// General
import "./navbar-quick-action.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../../services/data.service";
// Static Data
import userConst from "../../../../../const/userConst";
import routeConst from "../../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateOnboardingSugarbookVerifiedDialog } from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";

const NavbarQuickAction = (props) => {
  const { view = "mobile" } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Custom Hooks
  const getIcon = IconManager();

  // Event Handlers | Button
  const onUpgrade = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-48.2",
      },
    });

    onNavigate(routeConst.upgrade.path);
  };
  const onVerifiedProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-48.3",
      },
    });

    dispatch(updateOnboardingSugarbookVerifiedDialog(true));
  };

  // Utility Functions
  const isFacialVerificationRequired = () => {
    return (
      !getProfileData?.data?.verifications?.facial?.verified &&
      getProfileData?.data?.verifications?.facial?.verification_display
    );
  };

  // Render Functions
  const renderUpgradeButton = () => {
    return (
      <div
        className={`upgrade-now-button ${
          view === "desktop" ? "desktop-upgrade-now-button" : ""
        }`}
        onClick={onUpgrade}
      >
        {getIcon("premiumStarIcon", "premium-star-icon")}
        {t("profile.upgrade_now")}
      </div>
    );
  };
  const renderVerifiedButton = () => {
    return (
      <div
        className={`verified-now-button ${
          view === "desktop" ? "desktop-verified-now-button" : ""
        }`}
        onClick={onVerifiedProfile}
      >
        {getIcon("verifiedProfileBadge", "verified-profile-icon")}
        {t("profile.verify_profile")}
      </div>
    );
  };
  const renderButtons = () => {
    if (isDaddy) {
      if (
        getProfileData?.data?.membership_type === userConst.membershipType.free
      ) {
        return renderUpgradeButton();
      } else if (isFacialVerificationRequired()) {
        return renderVerifiedButton();
      }
    } else {
      if (isFacialVerificationRequired()) {
        return renderVerifiedButton();
      } else if (
        getProfileData?.data?.membership_type === userConst.membershipType.free
      ) {
        return renderUpgradeButton();
      }
    }
    return null;
  };

  return <div id="navbar-quick-action-subcomponent">{renderButtons()}</div>;
};

export default NavbarQuickAction;
