// General
import "./premium-benefits.scss";
// Static Data
import registrationConst from "../../../../const/registrationConst";
import userConst from "../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateJourneyNavigationPassthrough,
  updatePremiumBenefitsPassthrough,
} from "../../../../redux/store/registrationStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const PremiumBenefits = () => {
  // Redux variables
  const wantToBe = useSelector((state) => state.signup.wantToBe);
  const isCompleteJourneyLoading = useSelector(
    (state) => state.registration.isCompleteJourneyLoading
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Const variables
  const premiumBenefitsConst = [
    {
      label: t("registration.premium_benefits.option_1"),
      image: getIcon("unlimitedMessagesIcon", "unlimited-messages-icon"),
    },
    {
      label: t("registration.premium_benefits.option_2"),
      image: getIcon("profileViewIcon", "profile-view-icon"),
    },
    {
      label: t("registration.premium_benefits.option_4"),
      image: getIcon("messageReadIcon", "message-read-icon"),
    },
    {
      label: t("registration.premium_benefits.option_8"),
      image: getIcon("personalizedAboutMeIcon", "about-me-icon"),
    },
    {
      label: t("registration.premium_benefits.option_5"),
      image: getIcon("heartFilledIcon", "heart-filled-icon"),
    },
    {
      label: t("registration.premium_benefits.option_6"),
      image: getIcon("whiteSearchFilterIcon", "white-search-filter-icon"),
    },
    {
      label: t("registration.premium_benefits.option_3"),
      image: getIcon("profileApprovalIcon", "profile-approval-icon"),
    },
    {
      label: t("registration.premium_benefits.option_7"),
      image: getIcon("whiteLockIcon", "white-lock-icon"),
    },
  ];
  const sbPremiumBenefitsConst = [
    {
      label: t("registration.premium_benefits.sb_option_1"),
      image: getIcon("premiumStarIcon", "unlimited-messages-icon"),
    },
    {
      label: t("registration.premium_benefits.sb_option_2"),
      image: getIcon("profileViewIcon", "profile-view-icon"),
    },
    {
      label: t("registration.premium_benefits.sb_option_3"),
      image: getIcon("messageReadIcon", "message-read-icon"),
    },
    {
      label: t("registration.premium_benefits.sb_option_4"),
      image: getIcon("calendarSlashIcon", "calendar-slash-icon"),
    },

    {
      label: t("registration.premium_benefits.sb_option_5"),
      image: getIcon("locationSharpIcon", "location-sharp-icon"),
    },
    {
      label: t("registration.premium_benefits.option_3"),
      image: getIcon("profileApprovalIcon", "profile-approval-icon"),
    },
    {
      label: t("registration.premium_benefits.sb_option_7"),
      image: getIcon("userSecretIcon", "user-secret-icon"),
    },
  ];

  // Event Handlers | Button
  const onNextPage = () => {
    if (!isFormValid()) return;

    if (wantToBe === userConst.wantToBe.sugardaddy.value) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-62-62.17-62.17.1",
        },
      });

      dispatch(
        updatePremiumBenefitsPassthrough(
          registrationConst.premiumBenefits.upgrade
        )
      );
      dispatch(updateJourneyNavigationPassthrough({}));
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-62-62.17-62.17.3",
        },
      });

      dispatch(
        updatePremiumBenefitsPassthrough(registrationConst.premiumBenefits.skip)
      );
      dispatch(updateJourneyNavigationPassthrough({}));
    }
  };
  const onSkip = () => {
    if (isCompleteJourneyLoading) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.17-62.17.2",
      },
    });

    dispatch(
      updatePremiumBenefitsPassthrough(registrationConst.premiumBenefits.skip)
    );
    dispatch(updateJourneyNavigationPassthrough({}));
  };

  // Utility Functions
  const isFormValid = () => {
    return true;
  };

  return (
    <div id="premium-benefits-page">
      <div className="top-container">
        <div className="premium-benefits-label">
          {wantToBe === userConst.wantToBe.sugardaddy.value
            ? t("registration.premium_benefits.title")
            : t("registration.premium_benefits.sb_title")}
        </div>

        <div className="premium-benefits-description">
          {wantToBe === userConst.wantToBe.sugardaddy.value
            ? t("registration.premium_benefits.description")
            : t("registration.premium_benefits.sb_description")}
        </div>

        <div className="premium-benefits-body-container">
          {wantToBe === userConst.wantToBe.sugardaddy.value
            ? premiumBenefitsConst?.map((benefits, index) => (
                <div className="premium-benefits-content-container" key={index}>
                  <div className="premium-benefits-icon-container">
                    {benefits?.image}
                  </div>
                  <div className="premium-benefits-content-label">
                    {benefits?.label}
                  </div>
                </div>
              ))
            : sbPremiumBenefitsConst?.map((benefits, index) => (
                <div className="premium-benefits-content-container" key={index}>
                  <div className="premium-benefits-icon-container">
                    {benefits?.image}
                  </div>
                  <div className="premium-benefits-content-label">
                    {benefits?.label}
                  </div>
                </div>
              ))}
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`continue-button ${
            isMobile ? "full-width" : "fit-content"
          } ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onNextPage}
        >
          {wantToBe === userConst.wantToBe.sugardaddy.value
            ? t("registration.premium_benefits.upgrade_premium")
            : t("common.continue")}
        </div>

        {wantToBe === userConst.wantToBe.sugardaddy.value && (
          <div className="limited-access-button" onClick={onSkip}>
            {isCompleteJourneyLoading ? (
              <Spinner size={19} isPadding={false} color={"white-spinner"} />
            ) : (
              t("registration.premium_benefits.limited_access_is_fine")
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PremiumBenefits;
