// General
import "./messages-list.scss";
import { useEffect } from "react";
// Static Data
import routeConst from "../../../../const/routeConst";
import userConst from "../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Conversation Functions
  updateConversationId,
  updateConversationIdInt,
  updateConversationUser,
  resetConversationObj,

  // Private Call Functions
  updateOtherUserPrivateCallEnable,

  // Utility Functions
  updateConversationSelectPassthrough,
  updateSelectedConversation,
} from "../../../../redux/store/inboxStore";
import {
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateGender,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateSpecialBadges,
  updateProfilePhoto,
  updateProfileDecorations,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateAboutMeManualText,
  updateAboutMeReadStatus,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateAlcoholPreference,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // About Me Functions
  updatePersonalityType,
  updateHobbies,
  updateFitnessLevel,
  updateStarSign,
  updateDominantOrSubmissive,
  updatePersonalityTraits,

  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsOnline,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
} from "../../../../redux/store/userCardStore";
import { updateVerifiedCallerDescriptionDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Divider, useMediaQuery } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import useGetReadableDate from "../../../utility/custom-hooks/useGetReadableDate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../elements/custom-avatar/custom-avatar";
import VerifiedProfileBadge from "../../elements/verified-profile-badge/verified-profile-badge";

const MessagesList = (props) => {
  const {
    // User Data
    userId,
    userIdInt,
    username,
    age,
    gender,
    location,
    membershipType,
    verifiedProfile,
    verifiedCaller,
    levellingBadge,
    profilePhoto,
    profileDecorations,
    publicPhotos,
    privatePhotos,
    torTags,
    profileAchievementBadge,
    aboutMe,
    aboutMeReadStatus,
    lookingFor,
    occupation,
    lifestyle,
    education,
    relationship,
    smoking,
    drinking,
    alcoholPreference,
    height,
    bodyType,
    ethnicity,
    eyeColor,
    hairColor,
    children,
    // About Me Functions
    personalityType,
    hobbies,
    fitnessLevel,
    starSign,
    dominantOrSubmissive,
    personalityTraits,
    // User Utility
    shouldCallApi,
    privatePhotoAbleToRequest,
    privatePhotoRequested,
    privatePhotoApproval,
    requestedMyPrivatePhoto,
    approvedMyPrivatePhoto,
    isOnline,
    isBlocked,
    isSilentInteraction,
    isFavorited,
    isCallEnabled,
    isSugarbaby,
    role,

    // Conversation Data
    conversationId,
    conversationIdInt,
    livestreamingAchievementBadge,
    specialBadges,
    privateCallSettings,
    lastMessageContent,
    lastMessageTimestamp,
    lastMessageMine,
    lastMessageSeen,
    callStatus,
    callDuration,
    ownMembershipType,
  } = props;

  // Render variables
  let lastSeenView;

  // Redux variables
  const selectedConversation = useSelector(
    (state) => state.inbox.selectedConversation
  );
  const newUserConversation = useSelector(
    (state) => state.inbox.newUserConversation
  );
  const newUserConversationPassthrough = useSelector(
    (state) => state.inbox.newUserConversationPassthrough
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 850px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getReadableDate = useGetReadableDate();
  const getIcon = IconManager();

  // Lifecycle | Check for update | newUserConversationPassthrough
  useEffect(() => {
    if (!newUserConversationPassthrough) return;

    if (newUserConversation?.other_user?.id_int === userIdInt) {
      onMessageUser();
    }
  }, [newUserConversationPassthrough]);

  // Event Handlers | Button
  const onMessageUser = () => {
    // Resetting the conversationObj so that conversation-list.js won't have duplicate data from API too
    dispatch(resetConversationObj());

    // Assigning idInt to userCardStore
    // Use in report page
    dispatch(updateIdInt(userIdInt));

    const conversationUserObj = {
      // User Data
      userId,
      userIdInt,
      username,
      age,
      location,
      membershipType,
      verifiedProfile,
      verifiedCaller,
      levellingBadge,
      profilePhoto,
      profileDecorations,
      publicPhotos,
      privatePhotos,
      torTags,
      profileAchievementBadge,
      aboutMe,
      aboutMeReadStatus,
      lookingFor,
      occupation,
      lifestyle,
      education,
      relationship,
      smoking,
      drinking,
      alcoholPreference,
      height,
      bodyType,
      ethnicity,
      eyeColor,
      hairColor,
      children,
      // About Me
      personalityType,
      hobbies,
      fitnessLevel,
      starSign,
      dominantOrSubmissive,
      personalityTraits,
      // User Utility
      isSilentInteraction,

      // Conversation Data
      id: conversationId,
      idInt: conversationIdInt,
      livestreamingAchievementBadge,
      specialBadges,
    };

    dispatch(updateConversationUser(conversationUserObj));
    dispatch(updateConversationId(conversationId));
    dispatch(updateConversationIdInt(conversationIdInt));
    dispatch(
      updateOtherUserPrivateCallEnable(
        privateCallSettings?.system_private_calls_enabled &&
          privateCallSettings?.enable_calls
      )
    );

    // setSelectedConversation(conversationId);
    dispatch(updateSelectedConversation(conversationId));

    if (isMobile) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-8-Mobile-To-8.1-Button",
        },
      });

      onNavigate(
        `${routeConst.inbox.messaging.chat.chatId.clearPath}${conversationId}`
      );
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-8-Desktop-Open-8.1-Button",
        },
      });

      // Clearing the passthrough object of conversation ID and assigning it again after timeout
      dispatch(updateConversationSelectPassthrough({}));
      setTimeout(() => {
        dispatch(updateConversationSelectPassthrough(conversationUserObj));
      }, 500);
    }
  };
  const onViewProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-8-8.2-Button",
      },
    });

    // User View
    dispatch(updateId(userId));
    dispatch(updateIdInt(userIdInt));
    dispatch(updateUsername(username));
    dispatch(updateAge(age));
    dispatch(updateGender(gender));
    dispatch(updateLocation(location));
    dispatch(updateMembershipType(membershipType));
    dispatch(updateVerifiedProfile(verifiedProfile));
    dispatch(updateVerifiedCaller(verifiedCaller));
    dispatch(updateLevellingBadge(levellingBadge));
    dispatch(
      updateLivestreamingAchievementBadge(livestreamingAchievementBadge)
    );
    dispatch(updateSpecialBadges(specialBadges));
    dispatch(updateProfilePhoto(profilePhoto));
    dispatch(updateProfileDecorations(profileDecorations));
    dispatch(updatePublicPhotos(publicPhotos));
    dispatch(updatePrivatePhotos(privatePhotos));
    dispatch(updateTorTags(torTags));
    dispatch(updateProfileAchievementBadge(profileAchievementBadge));
    dispatch(updateAboutMeManualText(aboutMe));
    dispatch(updateAboutMeReadStatus(aboutMeReadStatus));
    dispatch(updateLookingFor(lookingFor));
    dispatch(updateOccupation(occupation));
    dispatch(updateLifestyle(lifestyle));
    dispatch(updateEducation(education));
    dispatch(updateRelationship(relationship));
    dispatch(updateSmoking(smoking));
    dispatch(updateDrinking(drinking));
    dispatch(updateHeight(height));
    dispatch(updateAlcoholPreference(alcoholPreference));
    dispatch(updateBodyType(bodyType));
    dispatch(updateEthnicity(ethnicity));
    dispatch(updateEyeColor(eyeColor));
    dispatch(updateHairColor(hairColor));
    dispatch(updateChildren(children));
    // About Me Functions
    dispatch(updatePersonalityType(personalityType));
    dispatch(updateHobbies(hobbies));
    dispatch(updateFitnessLevel(fitnessLevel));
    dispatch(updateStarSign(starSign));
    dispatch(updateDominantOrSubmissive(dominantOrSubmissive));
    dispatch(updatePersonalityTraits(personalityTraits));
    // User Utility
    dispatch(updateShouldCallApi(shouldCallApi));
    dispatch(updatePrivatePhotoRequested(privatePhotoRequested));
    dispatch(updatePrivatePhotoApproval(privatePhotoApproval));
    dispatch(updateRequestedMyPrivatePhoto(requestedMyPrivatePhoto));
    dispatch(updateApprovedMyPrivatePhoto(approvedMyPrivatePhoto));
    dispatch(updateIsOnline(isOnline ? true : false));
    dispatch(updateIsBlocked(isBlocked ? true : false));
    dispatch(updateIsSilentInteraction(isSilentInteraction));
    dispatch(updateIsFavorited(isFavorited));
    dispatch(updateIsCallEnabled(isCallEnabled));
    dispatch(updateIsSugarbaby(isSugarbaby));
    dispatch(updateRole(role));

    onNavigate(`${routeConst.profile.view.clearPath}${userId}`);
  };
  const onOpenVerifiedCallerDialog = () => {
    dispatch(updateVerifiedCallerDescriptionDialog(true));
  };

  // Utility Functions
  const getLastMessage = () => {
    if (callStatus) {
      // Backend does not provide translated text for call status, so FE will have to translate
      switch (callStatus) {
        case "rejected":
          return t("inbox.call_declined");
        case "cancelled":
          return t("inbox.call_cancelled");
        case "ended":
          return `${t("inbox.call_ended")} (${getCallDuration(callDuration)})`;
        case "missed":
          return t("inbox.call_not_answered");
        default:
          break;
      }
    } else if (
      lastMessageContent
        ?.toLowerCase()
        .includes("just given you access to my private photos")
    ) {
      // Backend does not provide translated text for private photos request, so FE will have to translate
      return t("inbox.i_have_just_given_you_access");
    } else if (
      lastMessageContent
        ?.toLowerCase()
        .includes("request to view your private photos")
    ) {
      // Backend does not provide translated text for private photos request, so FE will have to translate
      return t("inbox.request_to_view_your_private_photos");
    } else {
      const cleanMessage = lastMessageContent?.replace(/<br\s*\/?>/gi, " ");
      return cleanMessage;
    }
  };
  const getCallDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  // Render Functions | Last Seen View
  if (ownMembershipType === userConst.membershipType.free) {
    // Free membership users can only see if the last message was seen or not
    if (!lastMessageMine && !lastMessageSeen) {
      lastSeenView = <div className="read"></div>;
    }
  } else {
    if (!lastMessageMine && !lastMessageSeen) {
      lastSeenView = <div className="read"></div>;
    } else if (!lastMessageMine && lastMessageSeen) {
      lastSeenView = <div></div>;
    } else if (lastMessageMine && lastMessageSeen) {
      lastSeenView = getIcon("blueTickIcon", "blue-tick-icon");
    } else {
      lastSeenView = getIcon("greyTickIcon", "grey-tick-icon");
    }
  }

  return (
    <div
      id="messages-tab-messages-list-subcomponent"
      className={
        selectedConversation === conversationId ? "selected-conversation" : ""
      }
    >
      <div className="padding-container">
        <div className="message-container">
          <div className="user-image-container" onClick={onViewProfile}>
            <CustomAvatar
              className="user-image"
              src={profilePhoto}
              role={role}
              isOnline={isOnline}
              profileBorder={profileDecorations?.profile_border_url}
            />
          </div>

          <div className="user-description-container" onClick={onMessageUser}>
            <div className="user-top-container">
              {livestreamingAchievementBadge?.length > 0 && (
                <div className="livestreaming-achievement-badge-container">
                  {livestreamingAchievementBadge?.map((badge, index) => (
                    <img
                      className="livestreaming-achievement-badge"
                      src={badge?.url}
                      alt={badge?.type}
                      key={index}
                    />
                  ))}
                </div>
              )}

              <div className="user-name">{username}</div>

              <div className="user-badge-container">
                {(membershipType === userConst.membershipType.premium ||
                  membershipType === userConst.membershipType.freePremium ||
                  membershipType === userConst.membershipType.diamond) && (
                  <div className="premium-mini-badge-container">
                    {getIcon("premiumMiniBadge", "premium-mini-badge")}
                  </div>
                )}
                {/* Verified Profile */}
                <div className="verified-profile-badge-container">
                  <VerifiedProfileBadge
                    verifiedProfile={verifiedProfile}
                    className={"verified-profile-badge"}
                  />
                </div>

                {/* Verified Caller */}
                {verifiedCaller && (
                  <div
                    className="verified-caller-badge-container"
                    onClick={onOpenVerifiedCallerDialog}
                  >
                    {getIcon("verifiedCallerBadge", "verified-caller-badge")}
                  </div>
                )}

                {/* Admin Label */}
                {membershipType === "admin" && (
                  <div className="admin-badge-container">
                    <PersonIcon className="person-icon" />
                    <div className="admin-label">{t("common.admin")}</div>
                  </div>
                )}

                {/* Levelling */}
                {levellingBadge && (
                  <div className="level-badge-container">
                    <img className="level-badge" src={levellingBadge} />
                  </div>
                )}

                {/* Special Badges */}
                {specialBadges?.length > 0 && (
                  <div className="special-badge-container">
                    {specialBadges?.map((badge, index) => (
                      <img
                        className="special-badge"
                        src={badge?.asset_short}
                        alt={"special"}
                        key={index}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="user-bottom-container">
              <div
                className="user-last-message"
                dangerouslySetInnerHTML={{ __html: getLastMessage() }}
              ></div>
            </div>
          </div>

          <div className="user-last-updated-container" onClick={onMessageUser}>
            <div className="last-updated">
              {getReadableDate(lastMessageTimestamp)}
            </div>

            {lastSeenView}
          </div>
        </div>
      </div>

      <Divider className="divider" light />
    </div>
  );
};

export default MessagesList;
