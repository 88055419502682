const userConst = {
  // Const Data
  gender: [
    { value: "man", title: "signup.opt_man" },
    { value: "woman", title: "signup.opt_woman" },
    { value: "non-binary", title: "signup.opt_non_binary" },
  ],
  wantToBe: {
    sugardaddy: { value: "sugardaddy", title: "signup.opt_sugar_daddy" },
    sugarbaby: { value: "sugarbaby", title: "signup.opt_sugar_baby" },
  },
  lookingFor: {
    man: [
      { value: "sugarbaby", title: "common.sugarbaby" },
      // { value: "sugardaddy", title: "common.sugarmommy" },
    ],
    woman: [
      // { value: "sugarbaby", title: "common.male_sugarbaby" },
      { value: "sugardaddy", title: "common.sugardaddy" },
    ],
  },
  interest: {
    man: { value: "man", title: "man" },
    woman: { value: "woman", title: "woman" },
  },
  indeptInfo: [
    {
      jsonTitle: "profile.role",
      title: "Role",
      value: "role",
      output: "",
      icon: "",
    },
    {
      jsonTitle: "profile.looking_for",
      title: "Looking For",
      value: "looking-for",
      output: "",
      icon: "",
    },
    {
      jsonTitle: "profile.lifestyle",
      title: "Lifestyle",
      value: "lifestyle",
      output: "",
      icon: "",
    },
    {
      jsonTitle: "profile.dating_budget",
      title: "Dating budget",
      value: "dating-budget",
      output: "",
      icon: "",
    },
    {
      jsonTitle: "profile.height",
      title: "Height",
      value: "height",
      output: "",
      icon: "",
    },
    {
      jsonTitle: "profile.body_type",
      title: "Body Type",
      value: "body-type",
      output: "",
      icon: "",
    },
    {
      jsonTitle: "profile.ethnicity",
      title: "Ethnicity",
      value: "ethnicity",
      output: "",
      icon: "",
    },
    {
      jsonTitle: "profile.eye_color",
      title: "Eye Color",
      value: "eye-color",
      output: "",
      icon: "",
    },
    {
      jsonTitle: "profile.hair_color",
      title: "Hair Color",
      value: "hair-color",
      output: "",
    },
    {
      jsonTitle: "profile.education",
      title: "Education",
      value: "education",
      output: "",
      icon: "",
    },
    {
      jsonTitle: "profile.occupation",
      title: "Occupation",
      value: "occupation",
      output: "",
      icon: "",
    },
    {
      jsonTitle: "profile.relationship",
      title: "Relationship",
      value: "relationship",
      output: "",
      icon: "",
    },
    {
      jsonTitle: "profile.children",
      title: "Children",
      value: "children",
      output: "",
      icon: "",
    },
    {
      jsonTitle: "profile.smoking",
      title: "Smoking",
      value: "smoking",
      output: "",
      icon: "",
    },
    {
      jsonTitle: "profile.drinking",
      title: "Drinking",
      value: "drinking",
      output: "",
      icon: "",
    },
    // {
    //   jsonTitle: "profile.annual_income",
    //   title: "Annual Income",
    //   value: "annual-income",
    //   output: "",
    //   icon: "",
    // },
    // {
    //   jsonTitle: "profile.net_worth",
    //   title: "Networth",
    //   value: "networth",
    //   output: "",
    //   icon: "",
    // },
  ],
  lifestyle: [
    {
      jsonTitle: "filter.opt_lifestyle.negotiable",
      title: "Negotiable",
      value: "negotiable",
      output: "",
      toSearchApi: "negotiable",
    },
    {
      jsonTitle: "filter.opt_lifestyle.minimal",
      title: "Minimal",
      value: "minimal",
      output: "",
      toSearchApi: "minimal",
    },
    {
      jsonTitle: "filter.opt_lifestyle.practical",
      title: "Practical",
      value: "practical",
      output: "",
      toSearchApi: "practical",
    },
    {
      jsonTitle: "filter.opt_lifestyle.moderate",
      title: "Moderate",
      value: "moderate",
      output: "",
      toSearchApi: "moderate",
    },
    {
      jsonTitle: "filter.opt_lifestyle.substantial",
      title: "Substantial",
      value: "substantial",
      output: "",
      toSearchApi: "substantial",
    },
    {
      jsonTitle: "filter.opt_lifestyle.high",
      title: "High",
      value: "high",
      output: "",
      toSearchApi: "high",
    },
  ],
  networth: [
    {
      jsonTitle: "filter.opt_net_worth.100000",
      title: "$100,000",
      fromComApi: "100,000",
      fromApi: "100000",
      toApi: "100,000",
      toSpiApi: "100000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_net_worth.250000",
      title: "$250,000",
      fromComApi: "250,000",
      fromApi: "250000",
      toApi: "250,000",
      toSpiApi: "250000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_net_worth.500000",
      title: "$500,000",
      fromComApi: "500,000",
      fromApi: "500000",
      toApi: "500,000",
      toSpiApi: "500000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_net_worth.750000",
      title: "$750,000",
      fromComApi: "750,000",
      fromApi: "750000",
      toApi: "750,000",
      toSpiApi: "750000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_net_worth.1_million",
      title: "$1 million",
      fromComApi: "1,000,000",
      fromApi: "1000000",
      toApi: "1,000,000",
      toSpiApi: "1000000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_net_worth.2_million",
      title: "$2 million",
      fromComApi: "2,000,000",
      fromApi: "2000000",
      toApi: "2,000,000",
      toSpiApi: "2000000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_net_worth.5_million",
      title: "$5 million",
      fromComApi: "5,000,000",
      fromApi: "5000000",
      toApi: "5,000,000",
      toSpiApi: "5000000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_net_worth.10_million",
      title: "$10 million",
      fromComApi: "10,000,000",
      fromApi: "10000000",
      toApi: "10,000,000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_net_worth.50_million",
      title: "$50 million",
      fromComApi: "50,000,000",
      fromApi: "50000000",
      toApi: "50,000,000",
      toSpiApi: "50000000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_net_worth.100_million",
      title: "$100 million",
      fromComApi: "100,000,000",
      fromApi: "100000000",
      toApi: "100,000,000",
      toSpiApi: "100000000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_net_worth.100_million_plus",
      title: "$100 million ++",
      fromComApi: "100,000,000+",
      fromApi: "100000000+",
      toApi: "100,000,000+",
      toSpiApi: "100000000+",
      output: "",
    },
  ],
  annualIncome: [
    {
      jsonTitle: "filter.opt_income.50000",
      title: "$50,000",
      fromComApi: "50,000",
      fromApi: "50000",
      toApi: "50,000",
      toSpiApi: "50000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_income.75000",
      title: "$75,000",
      fromComApi: "75,000",
      fromApi: "75000",
      toApi: "75,000",
      toSpiApi: "75000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_income.100000",
      title: "$100,000",
      fromComApi: "100,000",
      fromApi: "100000",
      toApi: "100,000",
      toSpiApi: "100000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_income.125000",
      title: "$125,000",
      fromComApi: "125,000",
      fromApi: "125000",
      toApi: "125,000",
      toSpiApi: "125000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_income.150000",
      title: "$150,000",
      fromComApi: "150,000",
      fromApi: "150000",
      toApi: "150,000",
      toSpiApi: "150000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_income.175000",
      title: "$175,000",
      fromComApi: "175,000",
      fromApi: "175000",
      toApi: "175,000",
      toSpiApi: "175000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_income.200000",
      title: "$200,000",
      fromComApi: "200,000",
      fromApi: "200000",
      toApi: "200,000",
      toSpiApi: "200000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_income.250000",
      title: "$250,000",
      fromComApi: "250,000",
      fromApi: "250000",
      toApi: "250,000",
      toSpiApi: "250000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_income.300000",
      title: "$300,000",
      fromComApi: "300,000",
      fromApi: "300000",
      toApi: "300,000",
      toSpiApi: "300000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_income.400000",
      title: "$400,000",
      fromComApi: "400,000",
      fromApi: "400000",
      toApi: "400,000",
      toSpiApi: "400000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_income.500000",
      title: "$500,000",
      fromComApi: "500,000",
      fromApi: "500000",
      toApi: "500,000",
      toSpiApi: "500000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_income.750000",
      title: "$750,000",
      fromComApi: "750,000",
      fromApi: "750000",
      toApi: "750,000",
      toSpiApi: "750000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_income.1_million",
      title: "$1 million",
      fromComApi: "1,000,000",
      fromApi: "1000000",
      toApi: "1,000,000",
      toSpiApi: "1000000",
      output: "",
    },
    {
      jsonTitle: "filter.opt_income.1_million_plus",
      title: "$1 million ++",
      fromComApi: "1,000,000+",
      fromApi: "1000000+",
      toApi: "1,000,000+",
      toSpiApi: "1000000+",
      output: "",
    },
  ],
  height: [
    130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144,
    145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159,
    160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174,
    175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189,
    190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204,
    205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219,
    220, 221, 222, 223, 224, 225, 226, 227, 228, 229,
  ],
  editHeight: [
    "",
    "",
    130,
    131,
    132,
    133,
    134,
    135,
    136,
    137,
    138,
    139,
    140,
    141,
    142,
    143,
    144,
    145,
    146,
    147,
    148,
    149,
    150,
    151,
    152,
    153,
    154,
    155,
    156,
    157,
    158,
    159,
    160,
    161,
    162,
    163,
    164,
    165,
    166,
    167,
    168,
    169,
    170,
    171,
    172,
    173,
    174,
    175,
    176,
    177,
    178,
    179,
    180,
    181,
    182,
    183,
    184,
    185,
    186,
    187,
    188,
    189,
    190,
    191,
    192,
    193,
    194,
    195,
    196,
    197,
    198,
    199,
    200,
    201,
    202,
    203,
    204,
    205,
    206,
    207,
    208,
    209,
    210,
    211,
    212,
    213,
    214,
    215,
    216,
    217,
    218,
    219,
    220,
    221,
    222,
    223,
    224,
    225,
    226,
    227,
    228,
    229,
    "",
    "",
  ],
  bodyType: [
    {
      jsonTitle: "filter.opt_body_type.slim",
      title: "Slim",
      value: "slim",
      output: "",
      toSearchApi: "slim",
    },
    {
      jsonTitle: "filter.opt_body_type.athletic",
      title: "Athletic",
      value: "athletic",
      output: "",
      toSearchApi: "athletic",
    },
    {
      jsonTitle: "filter.opt_body_type.average",
      title: "Average",
      value: "average",
      output: "",
      toSearchApi: "average",
    },
    {
      jsonTitle: "filter.opt_body_type.a_few_extra_pounds",
      title: "A few extra pounds",
      value: "a-few-extra-pounds",
      output: "",
      toSearchApi: "a-few-extra-pounds",
    },
    {
      jsonTitle: "filter.opt_body_type.full__overweight",
      title: "Full / Overweight",
      value: "full-or-overweight",
      output: "",
      toSearchApi: "full-or-overweight",
    },
    {
      jsonTitle: "filter.opt_body_type.other",
      title: "Other",
      value: "other",
      output: "",
      toSearchApi: "other",
    },
  ],
  ethnicity: [
    {
      jsonTitle: "filter.opt_ethnicity.asian",
      title: "Asian",
      value: "asian",
      output: "",
      toSearchApi: "asian",
    },
    {
      jsonTitle: "filter.opt_ethnicity.black__african",
      title: "Black / African Descent",
      value: "black-or-african-descent",
      output: "",
      toSearchApi: "black",
    },
    {
      jsonTitle: "filter.opt_ethnicity.latin__hispanic",
      title: "Latin / Hispanic",
      value: "latin",
      output: "",
      toSearchApi: "latin",
    },
    {
      jsonTitle: "filter.opt_ethnicity.east_indian",
      title: "East Indian",
      value: "east-indian",
      output: "",
      toSearchApi: "east-indian",
    },
    {
      jsonTitle: "filter.opt_ethnicity.middle_eastern",
      title: "Middle Eastern",
      value: "middle-eastern",
      output: "",
      toSearchApi: "middle-eastern",
    },
    {
      jsonTitle: "filter.opt_ethnicity.mixed",
      title: "Mixed",
      value: "mixed",
      output: "",
      toSearchApi: "mixed",
    },
    {
      jsonTitle: "filter.opt_ethnicity.native_american",
      title: "Native American",
      value: "native-american",
      output: "",
      toSearchApi: "native-american",
    },
    {
      jsonTitle: "filter.opt_ethnicity.pacific_islander",
      title: "Pacific Islander",
      value: "pacific-islander",
      output: "",
      toSearchApi: "pacific-islander",
    },
    {
      jsonTitle: "filter.opt_ethnicity.white__caucasian",
      title: "White / Caucasian",
      value: "caucasian",
      output: "",
      toSearchApi: "caucasian",
    },
    {
      jsonTitle: "filter.opt_ethnicity.other",
      title: "Other",
      value: "other",
      output: "",
      toSearchApi: "other",
    },
  ],
  eyeColor: [
    {
      jsonTitle: "filter.opt_color.black",
      title: "Black",
      value: "black",
      output: "",
      toSearchApi: "black",
    },
    {
      jsonTitle: "filter.opt_color.blue",
      title: "Blue",
      value: "blue",
      output: "",
      toSearchApi: "blue",
    },
    {
      jsonTitle: "filter.opt_color.brown",
      title: "Brown",
      value: "brown",
      output: "",
      toSearchApi: "brown",
    },
    {
      jsonTitle: "filter.opt_color.grey",
      title: "Grey",
      value: "grey",
      output: "",
      toSearchApi: "grey",
    },
    {
      jsonTitle: "filter.opt_color.green",
      title: "Green",
      value: "green",
      output: "",
      toSearchApi: "green",
    },
    {
      jsonTitle: "filter.opt_color.hazel",
      title: "Hazel",
      value: "hazel",
      output: "",
      toSearchApi: "hazel",
    },
    {
      jsonTitle: "filter.opt_color.other",
      title: "Other",
      value: "other",
      output: "",
      toSearchApi: "other",
    },
  ],
  hairColor: [
    {
      jsonTitle: "filter.opt_color.auburn",
      title: "Auburn",
      value: "auburn",
      output: "",
      toSearchApi: "auburn",
    },
    {
      jsonTitle: "filter.opt_color.black",
      title: "Black",
      value: "black",
      output: "",
      toSearchApi: "black",
    },
    {
      jsonTitle: "filter.opt_color.blonde",
      title: "Blonde",
      value: "blonde",
      output: "",
      toSearchApi: "blonde",
    },
    {
      jsonTitle: "filter.opt_color.light_brown",
      title: "Light Brown",
      value: "light-brown",
      output: "",
      toSearchApi: "light-brown",
    },
    {
      jsonTitle: "filter.opt_color.dark_brown",
      title: "Dark Brown",
      value: "dark-brown",
      output: "",
      toSearchApi: "dark-brown",
    },
    {
      jsonTitle: "filter.opt_color.grey",
      title: "Grey",
      value: "grey",
      output: "",
      toSearchApi: "grey",
    },
    {
      jsonTitle: "filter.opt_color.red",
      title: "Red",
      value: "red",
      output: "",
      toSearchApi: "red",
    },
    {
      jsonTitle: "filter.opt_color.white",
      title: "White",
      value: "white",
      output: "",
      toSearchApi: "white",
    },
    {
      jsonTitle: "filter.opt_color.other",
      title: "Other",
      value: "other",
      output: "",
      toSearchApi: "other",
    },
  ],
  education: [
    {
      jsonTitle: "filter.opt_education.high_school",
      title: "High School",
      value: "highSchool",
      output: "",
      toSearchApi: "high-school",
    },
    {
      jsonTitle: "filter.opt_education.some_college",
      title: "Some College",
      value: "someCollege",
      output: "",
      toSearchApi: "some-college",
    },
    {
      jsonTitle: "filter.opt_education.associates_degree",
      title: "Associate Degree",
      value: "associateDegree",
      output: "",
      toSearchApi: "associates-degree",
    },
    {
      jsonTitle: "filter.opt_education.bachelors_degree",
      title: "Bachelors Degree",
      value: "bachelorsDegree",
      output: "",
      toSearchApi: "bachelors-degree",
    },
    {
      jsonTitle: "filter.opt_education.graduate_degree",
      title: "Graduate Degree",
      value: "graduateDegree",
      output: "",
      toSearchApi: "graduate-degree",
    },
    {
      jsonTitle: "filter.opt_education.phd_or_post_doctoral",
      title: "PhD / Post Doctoral",
      value: "phd_postDoctoral",
      output: "",
      toSearchApi: "phd-or-post-doctoral",
    },
  ],
  relationship: [
    {
      jsonTitle: "filter.opt_relationship.single",
      title: "Single",
      value: "single",
      output: "",
      toSearchApi: "single",
    },
    {
      jsonTitle: "filter.opt_relationship.divorced",
      title: "Divorced",
      value: "divorced",
      output: "",
      toSearchApi: "divorced",
    },
    {
      jsonTitle: "filter.opt_relationship.separated",
      title: "Separated",
      value: "separated",
      output: "",
      toSearchApi: "separated",
    },
    {
      jsonTitle: "filter.opt_relationship.married_but_looking",
      title: "Married But Looking",
      value: "married-but-looking",
      output: "",
      toSearchApi: "married-but-looking",
    },
    {
      jsonTitle: "filter.opt_relationship.widowed",
      title: "Widowed",
      value: "widowed",
      output: "",
      toSearchApi: "widowed",
    },
  ],
  children: [0, 1, 2, 3, 4, 5],
  smoking: [
    {
      jsonTitle: "filter.opt_smoking.non_smoker",
      title: "Non Smoker",
      value: "non-smoker",
      output: "",
      toSearchApi: "non-smoker",
    },
    {
      jsonTitle: "filter.opt_smoking.light_smoker",
      title: "Light Smoker",
      value: "light-smoker",
      output: "",
      toSearchApi: "light-smoker",
    },
    {
      jsonTitle: "filter.opt_smoking.heavy_smoker",
      title: "Heavy Smoker",
      value: "heavy-smoker",
      output: "",
      toSearchApi: "heavy-smoker",
    },
  ],
  drinking: [
    {
      jsonTitle: "filter.opt_drinking.non_drinker",
      title: "Non Drinker",
      value: "non-drinker",
      output: "",
      toSearchApi: "non-drinker",
    },
    {
      jsonTitle: "filter.opt_drinking.social_drinker",
      title: "Social Drinker",
      value: "social-drinker",
      output: "",
      toSearchApi: "social-drinker",
    },
    {
      jsonTitle: "filter.opt_drinking.heavy_drinker",
      title: "Heavy Drinker",
      value: "heavy-drinker",
      output: "",
      toSearchApi: "heavy-drinker",
    },
  ],

  // Enum Data
  userRole: {
    sugardaddy: "SD",
    sugarbaby: "SBF",
    sugarmommy: "SM",
    maleSugarbaby: "SBM",
  },
  membershipType: {
    free: "free",
    freePremium: "free-premium",
    premium: "premium",
    diamond: "diamond",
    admin: "admin",
  },
  comUserApprovalState: {
    required: "0",
    inReview: "1",
    approved: "2",
    rejected: "3",
  },
  photoApprovalStatus: {
    inReview: "IN REVIEW",
    approved: "APPROVED",
    rejected: "REJECTED",
  },
  profileGeneralApprovalStatus: {
    inReview: "pending",
    approved: "approved",
    rejected: "rejected",
  },
  userAgeRange: {
    min: 70,
    max: 18,
  },
};

export default userConst;
