// General
import "./top-user-container.scss";
// Static Data
import profileConst from "../../../../const/profileConst";
import userConst from "../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateVerifiedCallerDescriptionDialog } from "../../../../redux/store/dialogStore";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useGetMembershipBadge from "../../../utility/custom-hooks/useGetMembershipBadge-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import ProfileActionButtons from "../../../shared/elements/profile/profile-action-buttons/profile-action-buttons";
import TermsOfRelationship from "../../../shared/elements/profile/terms-of-relationship/terms-of-relationship";
import CustomAvatar from "../../../shared/elements/custom-avatar/custom-avatar";
import VerifiedProfileBadge from "../../../shared/elements/verified-profile-badge/verified-profile-badge";

const TopUserContainer = (props) => {
  const { userProfileType } = props;

  // Redux variables
  const profilePhoto = useSelector((state) => state.userCard.profilePhoto);
  const profileDecorations = useSelector(
    (state) => state.userCard.profileDecorations
  );
  const username = useSelector((state) => state.userCard.username);
  const age = useSelector((state) => state.userCard.age);
  const gender = useSelector((state) => state.userCard.gender);
  const wantToBe = useSelector((state) => state.userCard.wantToBe);
  const location = useSelector((state) => state.userCard.location);
  const torTags = useSelector((state) => state.userCard.torTags);
  const showLastActive = useSelector((state) => state.userCard.showLastActive);
  const lastActive = useSelector((state) => state.userCard.lastActive);
  const showMemberSince = useSelector(
    (state) => state.userCard.showMemberSince
  );
  const memberSince = useSelector((state) => state.userCard.memberSince);
  const verifiedProfile = useSelector(
    (state) => state.userCard.verifiedProfile
  );
  const verifiedCaller = useSelector((state) => state.userCard.verifiedCaller);
  const levellingBadge = useSelector((state) => state.userCard.levellingBadge);
  const livestreamingAchievementBadge = useSelector(
    (state) => state.userCard.livestreamingAchievementBadge
  );
  const specialBadges = useSelector((state) => state.userCard.specialBadges);
  const membershipType = useSelector((state) => state.userCard.membershipType);
  const role = useSelector((state) => state.userCard.role);
  const isOnline = useSelector((state) => state.userCard.isOnline);
  const dispatch = useDispatch();

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const getMembershipBadge = useGetMembershipBadge();
  const getIcon = IconManager();

  // Only works outside of useEffect
  if (i18n.language.toLowerCase().includes("zh-tw")) {
    moment.locale("zh-tw");
  } else {
    moment.locale("en");
  }

  // Event Handlers | Button
  const onOpenVerifiedCallerDialog = () => {
    dispatch(updateVerifiedCallerDescriptionDialog(true));
  };
  const onNewTab = (url) => {
    if (!url) return;

    window.open(url, "_blank");
  };

  // Utility Functions
  const getReadableDate = (date) => {
    if (date) {
      return moment.utc(date).local(i18n.language).format("YYYY/MM/DD");
      return moment.utc(date).local(i18n.language).format("DD MMM YYYY"); // Deprecated
    } else {
      return "N/A";
    }
  };
  const getRole = (role) => {
    switch (role?.toLowerCase()) {
      case userConst.userRole.sugardaddy.toLowerCase():
        return t("common.sugardaddy");
      case userConst.userRole.sugarmommy.toLowerCase():
        return t("common.sugarmommy");
      case userConst.userRole.sugarbaby.toLowerCase():
        return t("common.sugarbaby");
      case userConst.userRole.maleSugarbaby.toLowerCase():
        return t("common.male_sugarbaby");
      default:
        return "-";
    }
  };
  const getGender = (gender) => {
    switch (gender?.toLowerCase()) {
      case "man":
        return t("signup.opt_man");
      case "woman":
        return t("signup.opt_woman");
      case "lgbtq":
        return t("signup.opt_non_binary");
      default:
        break;
    }
  };

  return (
    <div id="user-profile-top-user-container-subcomponent">
      <div className="profile-image-container">
        <CustomAvatar
          className="profile-image"
          src={profilePhoto}
          role={role}
          profileBorder={profileDecorations?.profile_border_url}
        />
      </div>

      <div className="user-info-container">
        <div className="username-badge-container">
          {isOnline && <div className="status"></div>}
          {livestreamingAchievementBadge?.map((badge, index) => (
            <img
              className="livestreaming-achievement-badge"
              src={badge?.url}
              alt={badge?.type}
              key={index}
            />
          ))}

          <div className="username">{username || t("common.new_user")}</div>

          <ProfileActionButtons
            userProfileType={profileConst.userProfileType.page}
          />
        </div>

        <div className="age-role-country-container">
          <div className="age">{age}</div>
          <div className="small-circle"></div>
          <div className="role">{getGender(gender)}</div>

          <div className="badges-container">
            {/* Verified Profile */}
            <div className="verified-profile-badge-container">
              <VerifiedProfileBadge
                verifiedProfile={verifiedProfile}
                className={"verified-profile-badge"}
              />
            </div>

            {/* Verified Caller */}
            {verifiedCaller && (
              <div
                className="verified-caller-badge-container"
                onClick={onOpenVerifiedCallerDialog}
              >
                {getIcon("verifiedCallerBadge", "verified-caller-badge")}
              </div>
            )}

            {/* Levelling */}
            {levellingBadge && (
              <div className="badge-container">
                <img className="badge" src={levellingBadge} />
              </div>
            )}

            {/* Membership */}
            {membershipType !== userConst.membershipType.free && (
              <div className="membership-badge-container">
                <img
                  className="membership-badge"
                  src={getMembershipBadge(membershipType)}
                />
              </div>
            )}

            {/* Special Badges */}
            {specialBadges && (
              <div className="special-badges-container">
                {specialBadges?.map((badge, index) => (
                  <img
                    className="special-badges"
                    src={badge?.asset}
                    alt={"special"}
                    onClick={() => onNewTab(badge?.url)}
                    key={index}
                  />
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="tor-details-container">
          <TermsOfRelationship enableTitle={false} />
        </div>

        <div className="member-active-container">
          {showLastActive && (
            <div className="last-active-container">
              <div className="last-active-label">
                {t("profile.last_active")}
              </div>
              <div className="last-active">{getReadableDate(lastActive)}</div>
            </div>
          )}

          {showMemberSince && (
            <div className="member-since-container">
              <div className="member-since-label">
                {t("profile.member_since")}
              </div>
              <div className="member-since">{getReadableDate(memberSince)}</div>
            </div>
          )}

          <div className="location-container">
            <div className="location-label">{t("filter.recent_location")}</div>
            <div className="location">{location}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopUserContainer;
