// General
import "./account-signup.scss";
import { useState, useEffect, useRef } from "react";
// Services
import {
  useAuthSignupMutation,
  useAuthSignupV2Mutation,
  useInitiateEmailVerificationMutation,
} from "../../../services/data.service";
import { sessionService } from "../../../services/session.service";
import { emailRegex, removeHTMLEscape } from "../../../services/regex.service";
// Static Data
import routeConst from "../../../const/routeConst";
import userConst from "../../../const/userConst";
import user from "../../../enums/user";
import utilityConst from "../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateEmail,
  updatePassword,
  updateRetypePassword,
  updateIsPremium,
  updateSignupDate,
  updateUserId,
  updateGoogleRecaptchaToken,
  updateGender,
  updateLookingFor,
  updateInterest,
  updateWantToBe,
} from "../../../redux/store/signupStore";
import {
  // Email Verification Functions
  setEmailVerificationTimer,
  updateEmailVerificationDisplay,
  updateEmailVerificationRequired,

  // Mobile Verification Functions
  updateMobileVerificationDisplay,
  updateMobileVerificationRequired,
} from "../../../redux/store/verificationStore";
import {
  updateJourneyNavigationPassthrough,
  updateVerificationDataPassthrough,
} from "../../../redux/store/registrationStore";
import {
  updateApiToken,
  updateSpiToken,
} from "../../../redux/store/publicStore";
import { updateErrorToast } from "../../../redux/store/toastStore";
import { updateEmailRegisteredDialog } from "../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// react-google-recaptcha
import ReCAPTCHA from "react-google-recaptcha";
// Material UI
import {
  Box,
  TextField,
  MenuItem,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  IconButton,
  Checkbox,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const AccountSignup = () => {
  // API variables
  const [
    authSignup,
    {
      data: authSignupData,
      error: authSignupErrorData,
      isLoading: authSignupLoading,
      isSuccess: authSignupSuccess,
      isError: authSignupError,
    },
  ] = useAuthSignupMutation();
  const [
    authSignupV2,
    {
      data: authSignupV2Data,
      error: authSignupV2ErrorData,
      isLoading: authSignupV2Loading,
      isSuccess: authSignupV2Success,
      isError: authSignupV2Error,
    },
  ] = useAuthSignupV2Mutation();
  const [
    initiateEmailVerification,
    {
      data: initiateEmailVerificationData,
      error: initiateEmailVerificationErrorData,
      isLoading: initiateEmailVerificationLoading,
      isSuccess: initiateEmailVerificationSuccess,
      isError: initiateEmailVerificationError,
    },
  ] = useInitiateEmailVerificationMutation();

  // General variables
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [retypePasswordErrorMessage, setRetypePasswordErrorMessage] =
    useState("");
  const [retypePasswordFocus, setRetypePasswordFocus] = useState(false);
  const [retypePasswordValid, setRetypePasswordValid] = useState(false);
  const [retypePasswordError, setRetypePasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [tnc, setTnc] = useState(false);
  const [googleRecaptchaLoading, setGoogleRecaptchaLoading] = useState(false);
  const recaptchaRef = useRef();

  // Redux variables
  const country = useSelector((state) => state.signup.country);
  const phone = useSelector((state) => state.signup.phone);
  const email = useSelector((state) => state.signup.email);
  const password = useSelector((state) => state.signup.password);
  const retypePassword = useSelector((state) => state.signup.retypePassword);
  const gender = useSelector((state) => state.signup.gender);
  const lookingFor = useSelector((state) => state.signup.lookingFor);
  const wantToBe = useSelector((state) => state.signup.wantToBe);
  const role = useSelector((state) => state.signup.role);
  const interest = useSelector((state) => state.signup.interest);
  const facebookAuthToken = useSelector(
    (state) => state.signup.facebookAuthToken
  );
  const isFbSignup = useSelector((state) => state.signup.isFbSignup);
  const hasEmail = useSelector((state) => state.signup.hasEmail);
  const googleRecaptchaToken = useSelector(
    (state) => state.signup.googleRecaptchaToken
  );
  const utmSource = useSelector((state) => state.tracking.utmSource);
  const initiateEmailApiLoading = useSelector(
    (state) => state.registration.initiateEmailApiLoading
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Check for update | Auth Signup API Response
  useEffect(() => {
    if (authSignupLoading) {
    } else if (authSignupSuccess) {
      switch (authSignupData?.status) {
        case 1:
          dispatch(updateApiToken(authSignupData?.data?.legacy_token)); // Remove this after all API using SPI
          dispatch(updateSpiToken(authSignupData?.data?.token)); // Use this after move all API to SPI
          sessionService.setEmail(email);

          // GTM Use
          dispatch(
            updateIsPremium(
              authSignupData?.data?.user?.membership_type ===
                userConst.membershipType.premium ||
                authSignupData?.data?.user?.membership_type ===
                  userConst.membershipType.freePremium ||
                authSignupData?.data?.user?.membership_type ===
                  userConst.membershipType.diamond
            )
          );
          dispatch(
            updateSignupDate(moment().utc().format("YYYY-MM-DD HH:mm:ss"))
          );
          dispatch(updateUserId(authSignupData?.data?.user?.id_int));

          // Insider
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-RegistrationCompleted",
              email,
              role,
              is_premium:
                authSignupData?.data?.user?.membership_type ===
                  userConst.membershipType.premium ||
                authSignupData?.data?.user?.membership_type ===
                  userConst.membershipType.freePremium ||
                authSignupData?.data?.user?.membership_type ===
                  userConst.membershipType.diamond,
              signup_date: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
              user_id: authSignupData?.data?.user?.id_int,
            },
          });

          // Meta Pixel
          submitMeta();

          // Reddit Pixel
          submitRedditPixel();

          // Google Ads
          submitGoogleAds();

          // Save the state of email and mobile verification required
          // Used for checking if email and mobile verification is skippable
          dispatch(
            updateEmailVerificationDisplay(
              authSignupData?.data?.user?.verification?.email
                ?.verification_display
            )
          );
          dispatch(
            updateEmailVerificationRequired(
              authSignupData?.data?.user?.verification?.email
                ?.verification_required
            )
          );
          dispatch(
            updateMobileVerificationDisplay(
              authSignupData?.data?.user?.verification?.mobile
                ?.verification_display
            )
          );
          dispatch(
            updateMobileVerificationRequired(
              authSignupData?.data?.user?.verification?.mobile
                ?.verification_required
            )
          );

          if (
            !authSignupData?.data?.user?.verifications?.email?.verified &&
            authSignupData?.data?.user?.verifications?.email
              ?.verification_display
          ) {
            // Email Verification
            initiateEmailVerification();
          } else if (
            !authSignupData?.data?.user?.verifications?.mobile?.verified &&
            authSignupData?.data?.user?.verifications?.mobile
              ?.verification_display
          ) {
            // Phone Verification
            onNavigate(routeConst.phoneAuthentication.path);
          } else {
            // No Verifications, to Info Signup
            onNavigate(routeConst.infoSignup.path);
          }
          break;
        case -1:
          const errorToast = {
            message: authSignupData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToast));
          break;
        default:
          break;
      }
    } else if (authSignupError) {
      switch (authSignupErrorData?.data?.status) {
        case -1:
          const errorToast = {
            message: authSignupErrorData?.data?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToast));
          break;
        default:
          const warningToast = {
            message: authSignupErrorData?.data?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(warningToast));
          break;
      }
    }
  }, [authSignupLoading, authSignupSuccess, authSignupError]);

  // Lifecycle | Check for update | Auth Signup V2 API Response
  useEffect(() => {
    if (authSignupV2Loading) {
    } else if (authSignupV2Success) {
      switch (authSignupV2Data?.status) {
        case -1:
          if (authSignupV2Data?.message === "email.registered") {
            dispatch(updateEmailRegisteredDialog(true));
          } else {
            const errorToast = {
              message: authSignupV2Data?.message,
              autoClose: 3000,
            };
            dispatch(updateErrorToast(errorToast));
          }
          break;
        default:
          dispatch(updateApiToken(authSignupV2Data?.auth?.legacy_token)); // Remove this after all API using SPI
          dispatch(updateSpiToken(authSignupV2Data?.auth?.token)); // Use this after move all API to SPI
          sessionService.setEmail(email);

          // GTM Use // Need to revisit
          // dispatch(
          //   updateIsPremium(
          //     authSignupV2Data?.user?.membership_type ===
          //       userConst.membershipType.premium ||
          //       authSignupV2Data?.user?.membership_type ===
          //         userConst.membershipType.freePremium ||
          //       authSignupV2Data?.user?.membership_type ===
          //         userConst.membershipType.diamond
          //   )
          // );
          dispatch(
            updateSignupDate(moment().utc().format("YYYY-MM-DD HH:mm:ss"))
          );
          dispatch(updateUserId(authSignupV2Data?.user?.id_int));

          // Insider
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-RegistrationCompleted",
              email,
              role,
              is_premium: false,
              signup_date: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
              user_id: authSignupV2Data?.user?.id_int,
            },
          });

          // Meta Pixel
          // submitMeta();

          // Reddit Pixel
          // submitRedditPixel();

          // Save the state of email and mobile verification required
          // Used for checking if email and mobile verification is skippable
          dispatch(
            updateEmailVerificationDisplay(
              authSignupV2Data?.journey?.email_verification?.required
            )
          );
          dispatch(updateEmailVerificationRequired(true));
          dispatch(
            updateMobileVerificationDisplay(
              authSignupV2Data?.journey?.mobile_verification?.required
            )
          );
          dispatch(updateMobileVerificationRequired(true));

          const verificationObj = {
            email: {
              verified: authSignupV2Data?.journey?.email_verification?.verified,
              verification_display:
                authSignupV2Data?.journey?.email_verification?.required,
              verification_required: true,
            },
            mobile: {
              verified:
                authSignupV2Data?.journey?.mobile_verification?.verified,
              verification_display:
                authSignupV2Data?.journey?.mobile_verification?.required,
              verification_required: true,
            },
          };
          dispatch(updateVerificationDataPassthrough(verificationObj));
          // dispatch(updateJourneyNavigationPassthrough({}));
          onNavigate(routeConst.introductionSignup.path);

          // if (
          //   !authSignupV2Data?.journey?.email_verification?.verified &&
          //   authSignupV2Data?.journey?.email_verification?.required
          // ) {
          //   // Email Verification
          //   initiateEmailVerification();
          // } else if (
          //   !authSignupV2Data?.journey?.mobile_verification?.verified &&
          //   authSignupV2Data?.journey?.mobile_verification?.required
          // ) {
          //   // Phone Verification
          //   onNavigate(routeConst.phoneAuthentication.path);
          // } else {
          //   // No Verifications, to Info Signup
          //   onNavigate(routeConst.usernameSignup.path);
          // }
          break;
      }
    } else if (authSignupV2Error) {
      switch (authSignupV2ErrorData?.data?.status) {
        case -1:
          const errorToast = {
            message: authSignupV2ErrorData?.data?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToast));
          break;
        default:
          const warningToast = {
            message: authSignupV2ErrorData?.data?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(warningToast));
          break;
      }
    }
  }, [authSignupV2Loading, authSignupV2Success, authSignupV2Error]);

  // Lifecycle | Check for update | Initiate Email Verification API Response
  useEffect(() => {
    if (initiateEmailVerificationLoading) {
    } else if (initiateEmailVerificationSuccess) {
      switch (initiateEmailVerificationData?.status) {
        case 1:
          const createdAt =
            initiateEmailVerificationData?.data?.email_verification?.created_at;
          const expiredAt =
            initiateEmailVerificationData?.data?.email_verification?.expired_at;
          const diffInSeconds = moment(expiredAt).diff(createdAt, "seconds");

          dispatch(setEmailVerificationTimer(60));
          onNavigate(routeConst.verify.emailVerification.path);
          break;
        case -1:
          // Need to wait 3 minutes
          const errorToast = {
            message: initiateEmailVerificationData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToast));
          break;
        default:
          // Not sure
          break;
      }
    } else if (initiateEmailVerificationError) {
    }
  }, [
    initiateEmailVerificationLoading,
    initiateEmailVerificationSuccess,
    initiateEmailVerificationError,
  ]);

  // Event Handlers | Button
  const onSignup = async () => {
    if (!isFormValid()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-18-18.1-18.1.1-Button",
      },
    });

    setGoogleRecaptchaLoading(true);

    // Google Recaptcha Token
    await recaptchaRef.current
      .executeAsync()
      .then((token) => {
        setGoogleRecaptchaLoading(false);

        dispatch(updateGoogleRecaptchaToken(token));

        let obj = {
          email,
          password,
          gender,
          wanttobe: wantToBe,
          interest,
          gre_token: token,
          utm_data: utmSource?.organizedUtmSource || [],
        };

        if (isFbSignup) {
          obj.auth_method = utilityConst.authMethod.facebook;
          obj.auth_token = facebookAuthToken;
        } else {
          obj.auth_method = utilityConst.authMethod.emailPassword;
        }

        // authSignup(obj);
        authSignupV2(obj);
      })
      .catch((error) => {
        console.log("error");
        setGoogleRecaptchaLoading(false);
      });
  };
  const onOpenLink = (type) => {
    switch (type) {
      case "terms":
        if (i18n.language.toLowerCase().includes("zh-tw")) {
          window.open(routeConst.terms.pathAltZhTw, "_blank");
        } else if (i18n.language.toLowerCase().includes("ja")) {
          window.open(routeConst.terms.pathAltJaJp, "_blank");
        }

        window.open(routeConst.terms.pathAlt, "_blank");
        break;
      case "privacy":
        if (i18n.language.toLowerCase().includes("zh-tw")) {
          window.open(routeConst.privacy.pathAltZhTw, "_blank");
        } else if (i18n.language.toLowerCase().includes("ja")) {
          window.open(routeConst.privacy.pathAltJaJp, "_blank");
        }

        window.open(routeConst.privacy.pathAlt, "_blank");
        break;
      default:
        break;
    }
  };

  // Event Handlers | Button
  const onNavigateToTerms = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-18-18.1-18.1.2-Button",
      },
    });

    onNavigate(routeConst.terms.path);
  };
  const onNavigateToPrivacyPolicy = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-18-18.1-18.1.3-Button",
      },
    });

    onNavigate(routeConst.privacy.path);
  };
  const onSwitchGender = () => {
    if (gender === user.gender.MAN) {
      dispatch(updateGender(user.gender.WOMAN));
      dispatch(updateLookingFor(user.lookingFor.SUGARDADDY));
      dispatch(updateInterest(userConst.interest.man.value));
    } else if (gender === user.gender.WOMAN) {
      dispatch(updateGender(user.gender.LGBTQ));
      dispatch(updateLookingFor(user.lookingFor.SUGARBABY));
      dispatch(updateInterest(userConst.interest.man.value));
    } else if (gender === user.gender.LGBTQ) {
      dispatch(updateGender(user.gender.MAN));
      dispatch(updateLookingFor(user.lookingFor.SUGARBABY));
      dispatch(updateInterest(userConst.interest.woman.value));
    }
  };
  const onSwitchRole = () => {
    if (gender === user.gender.LGBTQ) {
      if (wantToBe === userConst.wantToBe.sugardaddy.value) {
        dispatch(updateWantToBe(userConst.wantToBe.sugarbaby.value));
        dispatch(updateInterest(userConst.interest.man.value));
      } else if (wantToBe === userConst.wantToBe.sugarbaby.value) {
        dispatch(updateWantToBe(userConst.wantToBe.sugardaddy.value));
        dispatch(updateInterest(userConst.interest.man.value));
      }
    }
  };
  const onSwitchInterest = () => {
    if (wantToBe === userConst.wantToBe.sugardaddy.value) {
      if (interest === userConst.interest.man.value) {
        dispatch(updateInterest(userConst.interest.woman.value));
      } else if (interest === userConst.interest.woman.value) {
        dispatch(updateInterest(userConst.interest.man.value));
      }
    } else if (wantToBe === userConst.wantToBe.sugarbaby.value) {
      if (interest === userConst.interest.man.value) {
        dispatch(updateInterest(userConst.interest.woman.value));
      } else if (interest === userConst.interest.woman.value) {
        dispatch(updateInterest(userConst.interest.man.value));
      }
    }
  };
  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Event Handlers | MUI Text Fields
  const onGenderChange = (event) => {
    dispatch(updateGender(event.target.value));

    if (event.target.value === "man") {
      dispatch(updateLookingFor(user.lookingFor.SUGARBABY));
    } else if (event.target.value === "woman") {
      dispatch(updateLookingFor(user.lookingFor.SUGARDADDY));
    }
  };
  const onLookingForChange = (event) => {
    dispatch(updateLookingFor(event.target.value));
  };
  const onEmailChange = (event) => {
    dispatch(updateEmail(event.target.value.trim().toLowerCase()));
  };
  const onEmailFocus = (event) => {
    setEmailFocus(true);
  };
  const onPasswordChange = (event) => {
    dispatch(updatePassword(event.target.value.trim()));
  };
  const onPasswordFocus = (event) => {
    setPasswordFocus(true);
  };
  const onRetypePasswordChange = (event) => {
    dispatch(updateRetypePassword(event.target.value.trim()));
  };
  const onRetypePasswordFocus = (event) => {
    setRetypePasswordFocus(true);
  };

  // Event Handlers | MUI Checkbox
  const onCheckboxChange = (event) => {
    setTnc(event.target.checked);
  };

  // Utility Functions
  const isFormValid = () => {
    return (
      email &&
      password &&
      retypePassword &&
      emailValid &&
      passwordValid &&
      retypePasswordValid &&
      tnc &&
      !googleRecaptchaLoading &&
      !authSignupLoading &&
      !authSignupV2Loading &&
      !initiateEmailVerificationLoading &&
      !initiateEmailApiLoading
    );
  };
  const emailValidation = () => {
    let valid = true;
    let error = false;

    const regex = emailRegex;

    if (email === "") {
      setEmailErrorMessage(t("login.email_address_is_required"));
      valid = false;
      error = true;
    } else if (!regex.test(email)) {
      setEmailErrorMessage(t("login.email_address_is_not_valid"));
      valid = false;
      error = true;
    } else if (removeHTMLEscape.test(email)) {
      dispatch(updateEmail(""));
      valid = false;
      error = true;

      // Send to Sentry
      Sentry.captureMessage("PWA-18-18.2-Email-Input-HTML-Escape-Detected");

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-18-18.2-Email-Input-HTML-Escape-Detected",
        },
      });
    } else {
      setEmailErrorMessage("");
      valid = true;
      error = false;
    }

    setEmailValid(valid);
    setEmailError(error);
  };
  const passwordValidation = () => {
    let valid = true;
    let error = false;

    if (password === "") {
      setPasswordErrorMessage(t("login.password_is_required"));
      valid = false;
      error = true;
    } else if (password.length < 6) {
      setPasswordErrorMessage(
        t("login.password_must_be_n_characters", { n: 6 })
      );
      valid = false;
      error = true;
    } else {
      setPasswordErrorMessage("");
      valid = true;
      error = false;
    }

    setPasswordValid(valid);
    setPasswordError(error);
  };
  const retypePasswordValidation = () => {
    let valid = true;
    let error = false;

    if (retypePassword === "") {
      setRetypePasswordErrorMessage(t("login.password_is_required"));
      valid = false;
      error = true;
    } else if (retypePassword.length < 6) {
      setRetypePasswordErrorMessage(
        t("login.password_must_be_n_characters", { n: 6 })
      );
      valid = false;
      error = true;
    } else if (retypePassword !== password) {
      setRetypePasswordErrorMessage(t("signup.password_does_not_match"));
      valid = false;
      error = true;
    } else {
      setRetypePasswordErrorMessage("");
      valid = true;
      error = false;
    }

    setRetypePasswordValid(valid);
    setRetypePasswordError(error);
  };
  const submitMeta = () => {
    if (window.fbq) {
      window.fbq("track", "CompleteRegistration", {
        email: email,
      });
    }
  };
  const submitRedditPixel = () => {
    // Inactive
    // if (window.rdt) {
    //   window.rdt("track", "SignUp");
    // }
  };
  const submitGoogleAds = () => {
    // Inactive
    // if (window.gtag) {
    //   window.gtag("event", "conversion", {
    //     send_to: "AW-16577831170/bXynCLDDm7MZEILC9uA9",
    //   });
    // }
  };
  const getGender = (gender) => {
    if (gender === user.gender.MAN) {
      return t("signup.opt_man");
    } else if (gender === user.gender.WOMAN) {
      return t("signup.opt_woman");
    } else if (gender === user.gender.LGBTQ) {
      return t("signup.opt_non_binary");
    }
  };
  const getLookingFor = (lookingFor) => {
    if (gender === user.gender.LGBTQ) {
      if (wantToBe === userConst.wantToBe.sugardaddy.value) {
        if (interest === userConst.interest.man.value) {
          return t("common.gay");
        } else if (interest === userConst.interest.woman.value) {
          return t("common.lesbian");
        }
      } else if (wantToBe === userConst.wantToBe.sugarbaby.value) {
        if (interest === userConst.interest.man.value) {
          return t("common.gay");
        } else if (interest === userConst.interest.woman.value) {
          return t("common.lesbian");
        }
      }
    } else {
      if (lookingFor === user.lookingFor.SUGARBABY) {
        return t("signup.opt_sugar_baby");
      } else if (lookingFor === user.lookingFor.SUGARDADDY) {
        return t("signup.opt_sugar_daddy");
      }
    }
  };
  const getRoles = (gender, lookingFor) => {
    if (gender === user.gender.MAN) {
      if (lookingFor === "sugardaddy") {
        return "sugarbaby";
      } else if (lookingFor === "sugarbaby") {
        return "sugardaddy";
      }
    } else if (gender === user.gender.WOMAN) {
      if (lookingFor === "sugardaddy") {
        return "sugarbaby";
      } else if (lookingFor === "sugarbaby") {
        return "sugardaddy";
      }
    } else if (gender === user.gender.LGBTQ) {
      if (wantToBe === userConst.wantToBe.sugardaddy.value) {
        return t(userConst.wantToBe.sugardaddy.title);
      } else if (wantToBe === userConst.wantToBe.sugarbaby.value) {
        return t(userConst.wantToBe.sugarbaby.title);
      }
    }
  };

  // Check for email and password validation
  useEffect(() => {
    emailValidation();
    passwordValidation();
    retypePasswordValidation();
  }, [
    email,
    password,
    retypePassword,
    emailFocus,
    passwordFocus,
    retypePasswordFocus,
    emailErrorMessage,
    passwordErrorMessage,
    retypePasswordErrorMessage,
  ]);

  return (
    <Box
      id="account-signup-page"
      component="form"
      noValidate
      autoComplete="off"
    >
      <div className="max-width-container">
        <div className="navbar-container">
          <div
            className="back-button"
            onClick={() => onNavigate(routeConst.join.path)}
          >
            {getIcon("signupBackIcon", "signup-back-icon")}
          </div>
        </div>

        <div className="create-account-label">
          {t("signup.create_new_account")}
        </div>

        <div className="gender-looking-for-container">
          <div className="gender-container">
            <div className="i-am-label">
              <div className="i-am-a-label">{t("signup.i_am_a_plain")}</div>
              <div className="selected-gender">{getGender(gender)}</div>

              <div className="switch-gender-container" onClick={onSwitchGender}>
                {getIcon("switchIcon", "switch-icon")}
                <div className="switch-gender-label">{t("common.switch")}</div>
              </div>
            </div>

            {gender === user.gender.LGBTQ && (
              <div className="non-binary-gender-options-container">
                <div className="i-am-a-label">
                  {gender ? t("signup.i_am_a_plain") : t("signup.i_am_a")}
                </div>
                <div className="selected-gender">{getRoles(gender)}</div>

                {gender && (
                  <div className="switch-role-container" onClick={onSwitchRole}>
                    {getIcon("switchIcon", "switch-icon")}
                    <div className="switch-gender-label">
                      {t("common.switch")}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="looking-for-container">
            <div className="looking-for-a-label">{t("signup.looking_for")}</div>

            <div className="selected-looking-for">
              {getLookingFor(lookingFor)}
            </div>

            {gender === user.gender.LGBTQ && (
              <div className="switch-role-container" onClick={onSwitchInterest}>
                {getIcon("switchIcon", "switch-icon")}
                <div className="switch-gender-label">{t("common.switch")}</div>
              </div>
            )}
          </div>
        </div>

        <div className="account-signup-form-field-container">
          <div className="email-form-field-container">
            <TextField
              className="email-form-field"
              required
              value={email}
              error={emailError}
              disabled={hasEmail}
              onChange={onEmailChange}
              placeholder={t("signup.email_address")}
              helperText={emailErrorMessage}
              variant="standard"
              onFocus={onEmailFocus}
            />
          </div>

          <div className="password-form-field-container">
            <TextField
              className="password-form-field"
              required
              value={password}
              error={passwordError}
              onChange={onPasswordChange}
              type={showPassword ? "text" : "password"}
              placeholder={t("signup.password")}
              helperText={passwordErrorMessage}
              variant="standard"
              onFocus={onPasswordFocus}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onShowPassword}>
                      {showPassword ? (
                        <VisibilityIcon className="visibility-icon" />
                      ) : (
                        <VisibilityOffIcon className="visibility-icon" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="password-form-field-container">
            <TextField
              className="password-form-field"
              required
              value={retypePassword}
              error={retypePasswordError}
              onChange={onRetypePasswordChange}
              type={showPassword ? "text" : "password"}
              placeholder={t("signup.retype_password")}
              helperText={retypePasswordErrorMessage}
              variant="standard"
              onFocus={onRetypePasswordFocus}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onShowPassword}>
                      {showPassword ? (
                        <VisibilityIcon className="visibility-icon" />
                      ) : (
                        <VisibilityOffIcon className="visibility-icon" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>

        <div className="agreement-checkbox-container">
          <FormGroup className="checkbox">
            <FormControlLabel
              control={
                <Checkbox
                  className="agreement-checkbox"
                  onChange={onCheckboxChange}
                />
              }
              label={t("signup.signup_checkbox")}
            />
          </FormGroup>
        </div>

        <div
          className={`continue-button ${
            isFormValid() ? "" : "disabled-button"
          }`}
          onClick={onSignup}
        >
          {authSignupV2Loading || initiateEmailApiLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.continue")
          )}
        </div>

        <div className="tnc-label">
          <Trans
            i18nKey={"signup.signup_tnc"}
            components={{
              terms: (
                <span
                  className="terms-label"
                  onClick={() => onOpenLink("terms")}
                />
              ),
              privacy: (
                <span
                  className="privacy-label"
                  onClick={() => onOpenLink("privacy")}
                />
              ),
            }}
          />
        </div>
      </div>

      <div className="recaptcha-container">
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env["REACT_APP_RECAPTCHA_V3_SITE_KEY"]}
          theme="light"
        />
      </div>
    </Box>
  );
};

export default AccountSignup;
