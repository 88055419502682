// General
import "./livestreaming.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateJourneyMutation,
} from "../../../../services/data.service";
// Static Data
import userConst from "../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateJoinLivestreamCommunity,
  updateJourneyNavigationPassthrough,
  updateJourneyDataPassthrough,
  updateRegistrationNavigationData,
} from "../../../../redux/store/registrationStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const LivestreamingDetails = () => {
  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateJourney,
    {
      data: updateJourneyData,
      error: updateJourneyErrorData,
      isLoading: updateJourneyLoading,
      isSuccess: updateJourneySuccess,
      isError: updateJourneyError,
    },
  ] = useUpdateJourneyMutation();
  const [
    skipJourney,
    {
      data: skipJourneyData,
      error: skipJourneyErrorData,
      isLoading: skipJourneyLoading,
      isSuccess: skipJourneySuccess,
      isError: skipJourneyError,
    },
  ] = useUpdateJourneyMutation();

  // Redux variables
  const wantToBe = useSelector((state) => state.signup.wantToBe);
  const joinLivestreamCommunity = useSelector(
    (state) => state.registration.joinLivestreamCommunity
  );
  const skippableJourney = useSelector(
    (state) => state.registration.skippableJourney
  );
  const registrationNavigationData = useSelector(
    (state) => state.registration.registrationNavigationData
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | updateJourney API Response
  useEffect(() => {
    if (updateJourneyLoading) {
    } else if (updateJourneySuccess) {
      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (updateJourneyError) {
      const warningToast = {
        message: updateJourneyErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(warningToast));
    }
  }, [updateJourneyLoading, updateJourneySuccess, updateJourneyError]);

  // Lifecycle | Check for update | skipJourney API Response
  useEffect(() => {
    if (skipJourneyLoading) {
    } else if (skipJourneySuccess) {
      const updatedItems = registrationNavigationData?.map((item, i) => ({
        ...item,
        skipped: i === 14 ? true : i > 14 ? false : item.skipped,
      }));
      dispatch(updateRegistrationNavigationData(updatedItems));

      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (skipJourneyError) {
    }
  }, [skipJourneyLoading, skipJourneySuccess, skipJourneyError]);

  // Event Handlers | Button
  const onSelectLivestreamingOptions = (option) => {
    dispatch(updateJoinLivestreamCommunity(option));
  };
  const onNextPage = () => {
    if (!isFormValid()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.15-62.15.1",
      },
    });

    let obj = {};

    if (wantToBe === userConst.wantToBe.sugardaddy.value) {
      obj = {
        journey_step: "15",
        journey_data: {
          livestream: {
            date_streamer: joinLivestreamCommunity?.id,
          },
        },
      };
    } else if (wantToBe === userConst.wantToBe.sugarbaby.value) {
      obj = {
        journey_step: "15",
        journey_data: {
          livestream: {
            join_livestream: joinLivestreamCommunity?.id,
          },
        },
      };
    }
    updateJourney(obj);
  };
  const onSkip = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.15-62.15.2",
      },
    });

    const obj = {
      journey_step: "15",
      journey_data: {
        looking_for: {},
      },
    };
    skipJourney(obj);
  };

  // Utility Functions
  const isFormValid = () => {
    return joinLivestreamCommunity && !updateJourneyLoading;
  };
  const getJoinLivestreamingData = () => {
    const category =
      wantToBe === userConst.wantToBe.sugardaddy.value
        ? "date_streamer"
        : "join_livestream";
    return (
      getJourneyQuestionsData?.selections?.find(
        (item) => item?.category === category
      )?.selections || []
    );
  };

  return (
    <div
      id="details-signup-livestreaming-details-subcomponent"
      className={isMobile ? "vertical" : "horizontal"}
    >
      {!isMobile && (
        <div className="left-container">
          <div className="livestreaming-phone-overlay-container">
            {/* <img
              className="registration-phone-overlay-background"
              src={getAsset("registrationLivestreamingPhoneOverlay")}
            /> */}

            <video
              className="livestreaming-sample-video-background"
              src={
                "https://image.sgrbk.com/assets/videos/livestream-snippet-sample-2.mp4"
              }
              playsInline
              autoPlay={true}
              muted={true}
              controls={false}
              loop={true}
            ></video>

            <video
              className="livestreaming-sample-video"
              src={
                "https://image.sgrbk.com/assets/videos/livestream-snippet-sample-2.mp4"
              }
              playsInline
              autoPlay={true}
              muted={true}
              controls={false}
              loop={true}
            ></video>
          </div>
        </div>
      )}

      <div className="right-container">
        <div className={`top-container ${isMobile ? "apply-height" : ""}`}>
          <div className="livestreaming-details-label">
            {t("registration.livestreaming.title")}
          </div>

          <div className="livestreaming-details-description">
            {wantToBe === userConst.wantToBe.sugardaddy.value
              ? t("registration.livestreaming.male_description")
              : t("registration.livestreaming.female_description")}
          </div>

          {isMobile && (
            <div className="livestreaming-phone-overlay-container">
              {/* <img
                className="registration-phone-overlay-background"
                src={getAsset("registrationLivestreamingPhoneOverlay")}
              /> */}

              {/* <div className="black-background"></div> */}

              <video
                className="livestreaming-sample-video-background"
                src={
                  "https://image.sgrbk.com/assets/videos/livestream-snippet-sample-2.mp4"
                }
                playsInline
                autoPlay={true}
                muted={true}
                controls={false}
                loop={true}
              ></video>

              <video
                className="livestreaming-sample-video"
                src={
                  "https://image.sgrbk.com/assets/videos/livestream-snippet-sample-2.mp4"
                }
                playsInline
                autoPlay={true}
                muted={true}
                controls={false}
                loop={true}
              ></video>
            </div>
          )}

          {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
            <Spinner />
          ) : (
            <div className="livestreaming-body-container">
              {getJoinLivestreamingData()?.map((item, index) => (
                <div
                  className={`livestreaming-item ${
                    isMobile ? "partial-width" : "fix-width"
                  } ${
                    item?.id === joinLivestreamCommunity?.id ? "selected" : ""
                  }`}
                  onClick={() => onSelectLivestreamingOptions(item)}
                  key={index}
                >
                  {item?.display_locale}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="bottom-container">
          <div
            className={`continue-button  ${
              isMobile ? "full-width" : "fit-content"
            } ${isFormValid() ? "" : "disabled-button"}`}
            onClick={onNextPage}
          >
            {updateJourneyLoading ? (
              <Spinner size={19} isPadding={false} color={"white-spinner"} />
            ) : (
              t("common.continue")
            )}
          </div>

          <div className="skip-button" onClick={onSkip}>
            {skipJourneyLoading ? (
              <Spinner size={19} isPadding={false} color={"white-spinner"} />
            ) : (
              t("common.skip")
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LivestreamingDetails;
