// General
import "./registration-navigation-bar.scss";
import { useEffect, useRef } from "react";
// Static Data
import userConst from "../../../../const/userConst";
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateRegistrationNavigationData,
  updateRegistrationActiveTab,
} from "../../../../redux/store/registrationStore";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const RegistrationNavigationBar = () => {
  // General variables
  const parentContainerRef = useRef(null);
  const childContainerRef = useRef(null);

  // Redux variables
  const registrationNavigationData = useSelector(
    (state) => state.registration.registrationNavigationData
  );
  const registrationActiveTab = useSelector(
    (state) => state.registration.registrationActiveTab
  );
  const wantToBe = useSelector((state) => state.signup.wantToBe);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Check for update | active
  useEffect(() => {
    if (registrationActiveTab) {
      const containerWidth = parentContainerRef?.current?.offsetWidth;
      const activeItemWidth = childContainerRef?.current?.offsetWidth;
      const activeItemOffset = childContainerRef?.current?.offsetLeft;

      const scrollLeft =
        activeItemOffset - containerWidth / 2 + activeItemWidth / 2;
      parentContainerRef.current.scrollLeft = scrollLeft;

      const updatedItems = registrationNavigationData?.map((item, i) => {
        if (i === registrationActiveTab) {
          return { ...item, isVisible: true, isClickable: true };
        } else {
          return item;
        }
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
    }
  }, [registrationActiveTab]);

  // Event Handlers | Button
  const onSetActive = (navigation) => {
    if (!navigation?.isClickable) return;

    dispatch(updateRegistrationActiveTab(navigation?.id));
    onNavigate(
      routeConst.detailsSignup.path,
      false,
      `?active=${navigation?.id}`
    );
  };

  // Utility Functions
  const getTitle = (index) => {
    const activeTab = registrationNavigationData[index];
    const activeTabId = activeTab?.id;

    if (index === registrationActiveTab) {
      if (activeTabId === 3) {
        return wantToBe === userConst.wantToBe.sugardaddy.value
          ? t("registration.navigation.dating-budget")
          : t("registration.navigation.lifestyle");
      } else {
        return t(`registration.navigation.${activeTab?.icon}`);
      }
    }

    return "";
  };

  // Render Functions
  const renderIcon = (icon) => {
    switch (icon) {
      case "interests":
        return getIcon("registrationSearchIcon", "registration-search-icon");
      case "income":
        return getIcon("registrationIncomeIcon", "registration-income-icon");
      case "networth":
        return getIcon(
          "registrationNetworthIcon",
          "registration-networth-icon"
        );
      case "lifestyle":
        if (wantToBe === userConst.wantToBe.sugardaddy.value) {
          return getIcon(
            "registrationDatingBudgetIcon",
            "registration-lifestyle-icon"
          );
        } else if (wantToBe === userConst.wantToBe.sugarbaby.value) {
          return getIcon(
            "registrationLifestyleIcon",
            "registration-lifestyle-icon"
          );
        }
      case "relationship":
        return getIcon(
          "registrationRelationshipStatusIcon",
          "registration-relationship-status-icon"
        );
      case "occupation":
        return getIcon(
          "registrationOccupationIcon",
          "registration-occupation-icon"
        );
      case "dating-styles":
        return getIcon(
          "registrationDatingStylesIcon",
          "registration-dating-styles-icon"
        );
      case "fantasies":
        return getIcon(
          "registrationFantasiesIcon",
          "registration-fantasies-icon"
        );
      case "ethnicity":
      case "race":
        return getIcon(
          "registrationEthnicityIcon",
          "registration-ethnicity-icon"
        );
      case "smoke":
        return getIcon("registrationSmokeIcon", "registration-smoke-icon");
      case "drink":
        return getIcon("registrationDrinkIcon", "registration-drink-icon");
      case "alcohol":
        return getIcon("registrationAlcoholIcon", "registration-alcohol-icon");
      case "about-me":
        return getIcon("registrationAboutIcon", "registration-about-icon");
      case "looking-for":
        return getIcon(
          "registrationLookingForIcon",
          "registration-looking-for-icon"
        );
      case "livestreaming":
        return getIcon(
          "registrationLivestreamingIcon",
          "registration-livestreaming-icon"
        );
      case "photos":
        return getIcon("registrationPhotoIcon", "registration-photo-icon");
      default:
        break;
    }
  };

  return (
    <div
      id="details-signup-registration-navigation-bar-subcomponent"
      className={
        isMobile
          ? "registration-navigation-bar-mobile-height"
          : "registration-navigation-bar-desktop-height"
      }
      ref={parentContainerRef}
    >
      {registrationNavigationData?.map((item, index) => (
        <div
          className="navigation-item-container"
          ref={index === registrationActiveTab ? childContainerRef : null}
          key={index}
          onClick={() => onSetActive(item)}
        >
          {item?.isVisible ? (
            <div
              className={`navigation-item-icon-container ${
                index === registrationActiveTab ? "active-index" : ""
              }`}
            >
              {renderIcon(item?.icon)}
            </div>
          ) : (
            <div className="empty-navigation-item"></div>
          )}

          <div className="navigation-item-name">{getTitle(index)}</div>
        </div>
      ))}
    </div>
  );
};

export default RegistrationNavigationBar;
