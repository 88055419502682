import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// Static Data
import { API_CONFIG_HEADERS } from "../const/apiConst";

// dev-sb-api.sgrbk.com // api.sgrbk.com
export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env["REACT_APP_API_URL"] }),
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (body) => ({
        url: "v1/auth",
        method: "POST",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
        body: body,
      }),
    }),
    signin: builder.mutation({
      query: (body) => ({
        url: "v1/auth",
        method: "POST",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
        body: body,
      }),
    }),
    passwordRecovery: builder.mutation({
      query: (body) => ({
        url: "v1/account/password_recovery",
        method: "POST",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
        body: body,
      }),
    }),
    updateAccountEmail: builder.mutation({
      query: (body) => ({
        url: "v1/account/update_email",
        method: "POST",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
        body: body,
      }),
    }),
    resendAccountEmailVerification: builder.mutation({
      query: (body) => ({
        url: "v1/account/update_email/resend",
        method: "POST",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
        body: body,
      }),
    }),
    updateAccountEmailIdentityVerification: builder.mutation({
      query: (code) => ({
        url: `v1/account/update_email/confirm/${code}`,
        method: "POST",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
      }),
    }),
    resendEmailVerification: builder.query({
      query: () => ({
        url: "v1/emails/verification",
        method: "GET",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
      }),
    }),
    getBadgeCount: builder.query({
      query: () => ({
        url: "v1/notification/badge_counts",
        method: "GET",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
      }),
    }),
    sendSupportTicket: builder.mutation({
      query: (body) => ({
        url: "v1/support/ticket/zendesk/submit",
        method: "POST",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS_FORM_DATA,
        body: body,
      }),
    }),
    getPwaPayments: builder.query({
      query: () => ({
        url: "v1/payments/pwa",
        method: "GET",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
      }),
    }),
    getPromotions: builder.query({
      query: () => ({
        url: "v1/promotion/enabled",
        method: "GET",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
      }),
    }),
    applyPromoCode: builder.mutation({
      query: (body) => ({
        url: "v1/promotion/apply",
        method: "POST",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
        body: body,
      }),
    }),
    removePromoCode: builder.mutation({
      query: (body) => ({
        url: "v1/promotion/delete",
        method: "POST",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
        body: body,
      }),
    }),
    getPhoneValidation: builder.query({
      query: (query) => ({
        url: `v1/validation/phone_number${query ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
      }),
    }),
    sendPhoneVerificationCode: builder.mutation({
      query: (body) => ({
        url: "v1/tac/send_verification_code",
        method: "POST",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
        body: body,
      }),
    }),
    verifyVerificationCode: builder.mutation({
      query: (body) => ({
        url: "v1/tac/verify_verification_code",
        method: "POST",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
        body: body,
      }),
    }),
    createCustomVariable: builder.mutation({
      query: (body) => ({
        url: "v1/settings/users/custom_variable",
        method: "POST",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
        body,
      }),
    }),
    getCustomVariable: builder.query({
      query: (param) => ({
        url: `v1/settings/users/custom_variable/${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
      }),
    }),
    updateCustomVariable: builder.mutation({
      query: (body) => ({
        url: "v1/settings/users/custom_variable",
        method: "PUT",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
        body,
      }),
    }),
    deleteCustomVariable: builder.mutation({
      query: (param) => ({
        url: `v1/settings/users/custom_variable/${param ?? ""}`,
        method: "DELETE",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
      }),
    }),
  }),
});

// tsbcom.sgrbk.com // apicom.sgrbk.com
export const comApi = createApi({
  reducerPath: "comApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env["REACT_APP_COM_API"] }),
  endpoints: (builder) => ({
    logout: builder.mutation({
      query: () => ({
        url: "pwa/api/v1/logout",
        method: "POST",
        headers: API_CONFIG_HEADERS.COM_HEADERS,
        body: {},
      }),
    }),
    getAio: builder.query({
      query: (param) => ({
        url: `pwa/api/v1/aio${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.COM_HEADERS,
      }),
    }),
    getProfilePwa: builder.query({
      query: () => ({
        url: "pwa/api/v1/profile/get",
        method: "GET",
        headers: API_CONFIG_HEADERS.COM_HEADERS,
      }),
      providesTags: ["OldOwnProfile", "UploadPhoto"],
    }),
    getProfileCheck: builder.query({
      query: () => ({
        url: "pwa/api/v1/profile/check",
        method: "GET",
        headers: API_CONFIG_HEADERS.COM_HEADERS,
      }),
      providesTags: ["ProfileCheck"],
    }),
    getSearchUserProfile: builder.query({
      query: (body) => ({
        url: "pwa/api/v1/profile/details",
        method: "POST",
        headers: API_CONFIG_HEADERS.COM_HEADERS,
        body: body,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [`UserProfileDialog-ID:${result?.payload?.message?.profile?.id}`]
          : ["UserProfileDialog"],
    }),
    updateProfile: builder.mutation({
      query: (body) => ({
        url: "pwa/api/v1/profile/update",
        method: "POST",
        headers: API_CONFIG_HEADERS.COM_HEADERS,
        body: body,
      }),
      invalidatesTags: ["OldOwnProfile", "ProfileCheck"],
    }),
    updateProfilePhoto: builder.mutation({
      query: (formData) => ({
        url: "pwa/api/v1/profile/update",
        method: "POST",
        headers: API_CONFIG_HEADERS.COM_HEADERS_FORM_DATA,
        body: formData,
      }),
      // invalidatesTags: ["UploadPhoto"],
    }),
    reportUser: builder.mutation({
      query: (body) => ({
        url: "pwa/api/v1/profile/report",
        method: "POST",
        headers: API_CONFIG_HEADERS.COM_HEADERS,
        body: body,
      }),
    }),
    getSearchBanners: builder.query({
      query: (param) => ({
        url: `api/v1/banners${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.COM_HEADERS,
      }),
      providesTags: ["AfterPayment"],
    }),
    getSearchMasqueradeBanners: builder.query({
      query: (param) => ({
        url: `api/v1/banners${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.COM_HEADERS,
      }),
    }),
    getAllSettings: builder.query({
      query: () => ({
        url: "pwa/api/v1/settings/all",
        method: "POST",
        headers: API_CONFIG_HEADERS.COM_HEADERS,
      }),
      providesTags: ["notificationSettings"],
    }),
    updateNotificationSettings: builder.mutation({
      query: (body) => ({
        url: "pwa/api/v1/settings/update",
        method: "POST",
        headers: API_CONFIG_HEADERS.COM_HEADERS,
        body: body,
      }),
      invalidatesTags: ["notificationSettings"],
    }),
    updatePassword: builder.mutation({
      query: (body) => ({
        url: "pwa/api/v1/settings/password/update",
        method: "POST",
        headers: API_CONFIG_HEADERS.COM_HEADERS,
        body: body,
      }),
    }),
    updateEmailActivation: builder.mutation({
      query: (body) => ({
        url: "api/v2/settings/email/activate",
        method: "POST",
        headers: API_CONFIG_HEADERS.COM_HEADERS,
        body: body,
      }),
    }),
    updateAccountDeactivation: builder.mutation({
      query: (body) => ({
        url: "pwa/api/v1/settings/account/deactivate",
        method: "POST",
        headers: API_CONFIG_HEADERS.COM_HEADERS,
        body: body,
      }),
    }),
    clearConversation: builder.mutation({
      query: (body) => ({
        url: "pwa/api/v1/messaging/conversation/messages/remove",
        method: "POST",
        headers: API_CONFIG_HEADERS.COM_HEADERS,
        body: body,
      }),
    }),
  }),
});

// dev-spi.sgrbk.com // spi.sgrbk.com
export const spiApi = createApi({
  reducerPath: "spiApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env["REACT_APP_SPI_API"] }),
  endpoints: (builder) => ({
    authSignin: builder.mutation({
      query: (body) => ({
        url: "api/v1/auth/signin",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    authSignup: builder.mutation({
      query: (body) => ({
        url: "api/v1/auth/signup",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    authSocial: builder.mutation({
      query: (body) => ({
        url: "api/v1/auth/social",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    authSignout: builder.query({
      query: () => ({
        url: "api/v1/auth/signout",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    authRefreshToken: builder.mutation({
      query: (body) => ({
        url: "api/v1/auth/refresh_token",
        method: "POST",
        headers: API_CONFIG_HEADERS.REFRESH_TOKEN_HEADERS,
        body,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: "api/v1/forgot-password",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    verifyForgotPassword: builder.mutation({
      query: (body) => ({
        url: "api/v1/forgot-password/verify",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    initiateMobileVerification: builder.mutation({
      query: (body) => ({
        url: "api/v1/user/mobile/verification/initiate",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    verifyMobileVerification: builder.mutation({
      query: (body) => ({
        url: "api/v1/user/mobile/verification/verify",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    initiateEmailVerification: builder.mutation({
      query: (body) => ({
        url: "api/v1/user/email/verification/initiate",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    verifyEmailVerification: builder.mutation({
      query: (body) => ({
        url: "api/v1/user/email/verification/verify",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    initiateFacialVerification: builder.mutation({
      query: (body) => ({
        url: "api/v1/user/facial/verification/initiate",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    verifyFacialVerification: builder.mutation({
      query: (body) => ({
        url: "api/v1/user/facial/verification/verify",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
      // invalidatesTags: ["VerifyFacialVerification"],
    }),
    initiateManualVerification: builder.mutation({
      query: (body) => ({
        url: "api/v1/user/manual/verification/initiate",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    getProfile: builder.query({
      query: () => ({
        url: "api/v1/profile/me",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      providesTags: ["OwnProfile", "AfterPayment", "VerifyFacialVerification"],
      keepUnusedDataFor: 86400, // 1 day
    }),
    getProfileCompletion: builder.query({
      query: () => ({
        url: "api/v1/profile/me",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      providesTags: ["ProfileCompletion"],
      keepUnusedDataFor: 86400, // 1 day
    }),
    getUserProfile: builder.query({
      query: (id) => ({
        url: `api/v1/profile/${id}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getUserAio: builder.query({
      query: (param) => ({
        url: `api/v1/user/aio${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      providesTags: ["OwnProfile", "AfterPayment"],
      keepUnusedDataFor: 86400, // 1 day
    }),
    updateUserProfile: builder.mutation({
      query: (body) => ({
        url: "api/v1/profile/update",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
      invalidatesTags: ["OwnProfile"],
    }),
    updateEditUserProfile: builder.mutation({
      // Only use in edit profile, prevent conflict with updateUserProfile
      query: (body) => ({
        url: "api/v1/profile/update",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
      invalidatesTags: ["EditProfile", "ProfileCompletion"],
    }),
    uploadUserPhoto: builder.mutation({
      query: (body) => ({
        url: "api/v1/profile/photos/upload",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    updateUserPhoto: builder.mutation({
      query: (body) => ({
        url: "api/v1/profile/photos/update",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    updateUserLocation: builder.mutation({
      query: (body) => ({
        url: "api/v1/location/user_update",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
      // invalidatesTags: ["OwnProfile"],
    }),
    getInvitationUserStats: builder.query({
      query: () => ({
        url: "api/v1/invitations/user/stats",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getPrivateCallFreeMinutes: builder.query({
      query: () => ({
        url: "api/v1/private-calls/free-minutes",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getLevellingTasks: builder.query({
      query: () => ({
        url: "api/v1/levelling/tasks",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      providesTags: ["LevellingTasks"],
    }),
    claimLevellingTasks: builder.mutation({
      query: (body) => ({
        url: "api/v1/levelling/tasks/claim",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
      invalidatesTags: ["LevellingTasks"],
    }),
    getVirtualCurrencyCoinBalance: builder.query({
      query: () => ({
        url: "api/v1/virtual_currencies/coins",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      providesTags: ["CoinBalance", "CoinPayment"],
    }),
    getDatingRecentlyActive: builder.query({
      query: ({ param, body }) => ({
        url: `api/v1/find${param ?? ""}`,
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    getDatingNearest: builder.query({
      query: ({ param, body }) => ({
        url: `api/v1/find${param ?? ""}`,
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    getDatingNewest: builder.query({
      query: ({ param, body }) => ({
        url: `api/v1/find${param ?? ""}`,
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    getDatingCollege: builder.query({
      query: ({ param, body }) => ({
        url: `api/v1/find${param ?? ""}`,
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    getDatingPremium: builder.query({
      query: ({ param, body }) => ({
        url: `api/v1/find${param ?? ""}`,
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    getDatingMasqueradeParty: builder.query({
      query: ({ param, body }) => ({
        url: `api/v1/find${param ?? ""}`,
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    getDatingFiltered: builder.query({
      query: ({ param, body }) => ({
        url: `api/v1/find${param ?? ""}`,
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    getTorList: builder.query({
      query: () => ({
        url: "api/v1/settings/tor/list",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    saveTorList: builder.mutation({
      query: (body) => ({
        url: "api/v1/settings/tor/save",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
      invalidatesTags: ["ProfileCompletion"],
    }),
    postFollowUser: builder.mutation({
      query: (body) => ({
        url: "api/v1/following-users/follow",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
      invalidatesTags: ["FollowsList"],
      // invalidatesTags: ["LivestreamingLeaderboardList", "PrivateCallLeaderboardList"],
    }),
    postUnfollowUser: builder.mutation({
      query: (body) => ({
        url: "api/v1/following-users/unfollow",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
      invalidatesTags: ["FollowsList"],
      // invalidatesTags: ["LivestreamingLeaderboardList", "PrivateCallLeaderboardList"],
    }),
    getFollowersList: builder.query({
      query: (param) => ({
        url: `api/v1/following-users/follower-list${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      providesTags: ["FollowsList"],
    }),
    getFollowingList: builder.query({
      query: (param) => ({
        url: `api/v1/following-users/following-list${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      providesTags: ["FollowsList"],
    }),
    getLifetimeGiftersList: builder.query({
      query: (param) => ({
        url: `api/v1/live-streaming/lifetime-gifters-list${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      providesTags: ["FollowsList"],
    }),
    postRemoveFollowers: builder.mutation({
      query: (body) => ({
        url: "api/v1/following-users/remove",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getLivestreamingChannels: builder.query({
      query: (param) => ({
        url: `api/v1/live-streaming/channels${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getLivestreamingChannel: builder.query({
      query: (id) => ({
        url: `api/v1/live-streaming/channels/${id}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    postLivestreamingEnterChannel: builder.mutation({
      query: (body) => ({
        url: "api/v1/live-streaming/channels/viewer/enter",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    postLivestreamingEnteredChannel: builder.mutation({
      query: (body) => ({
        url: "api/v1/live-streaming/channels/viewer/entered",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    postLivestreamingLeaveChannel: builder.mutation({
      query: (body) => ({
        url: "api/v1/live-streaming/channels/viewer/leave",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getLivestreamingEndChannel: builder.query({
      query: (param) => ({
        url: `api/v1/live-streaming/channels/viewer/end${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    postLivestreamingChannelChat: builder.mutation({
      query: (body) => ({
        url: "api/v1/live-streaming/channels/chat",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getLivestreamingBanners: builder.query({
      query: (param) => ({
        url: `api/v1/live-streaming/banners${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      providesTags: ["AfterPayment"],
    }),
    getLivestreamingTopBanners: builder.query({
      query: () => ({
        url: "api/v1/live-streaming/top-banners",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getLivestreamingPlayerFloatingBanners: builder.query({
      query: () => ({
        url: "api/v1/live-streaming/player-floating-banners",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getLivestreamingGiftersList: builder.query({
      query: (channelId) => ({
        url: `api/v1/live-streaming/channels/gifters-list${
          channelId ? channelId : ""
        }`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getLivestreamingFansLeaderboard: builder.query({
      query: (param) => ({
        url: `api/v1/live-streaming/fans-leaderboard${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      // providesTags: ["FollowUnfollowUser"]
    }),
    getLivestreamingViewersList: builder.query({
      query: (param) => ({
        url: `api/v1/live-streaming/channels/viewers-list${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      // providesTags: ["FollowUnfollowUser"]
    }),
    getLivestreamingViewersKeepAlive: builder.query({
      query: (body) => ({
        url: "api/v1/live-streaming/channels/viewer/keep-alive",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getLivestreamingContestList: builder.query({
      query: () => ({
        url: "api/v1/live-streaming/contest/list",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getLivestreamingContestLeaderboard: builder.query({
      query: (param) => ({
        url: `api/v1/live-streaming/contest/leaderboard${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      providesTags: ["LivestreamingLeaderboardList"],
    }),
    getLivestreamingLeaderboard: builder.query({
      query: (param) => ({
        url: `api/v1/live-streaming/leaderboard${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      providesTags: ["LivestreamingLeaderboardList"],
    }),
    getLivestreamingPkLeaderboard: builder.query({
      query: (param) => ({
        url: `api/v1/live-streaming/pk/leaderboard${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getLivestreamingPk: builder.query({
      query: () => ({
        url: "api/v1/live-streaming/pk",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getLivestreamingReportCriteria: builder.query({
      query: () => ({
        url: "api/v1/live-streaming/report-criteria",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getLivestreamingUserMeta: builder.query({
      query: (body) => ({
        url: "api/v1/live-streaming/user-meta",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getLivestreamingChannelRefreshInfo: builder.query({
      query: (param) => ({
        url: `api/v1/live-streaming/channels/refresh${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    postReportLivestreamingChannel: builder.mutation({
      query: (body) => ({
        url: "api/v1/live-streaming/channels/report",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getPrivateCallContestList: builder.query({
      query: () => ({
        url: "api/v1/private-calls/contest/list",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getPrivateCallContestLeaderboard: builder.query({
      query: (param) => ({
        url: `api/v1/private-calls/contest/leaderboard${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      providesTags: ["PrivateCallLeaderboardList"],
    }),
    getPrivateCallsLeaderboard: builder.query({
      query: (param) => ({
        url: `api/v1/private-calls/leaderboard${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      providesTags: ["PrivateCallLeaderboardList"],
    }),
    getPrivateCallsBanners: builder.query({
      query: () => ({
        url: "api/v1/private-calls/banners",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      providesTags: ["AfterPayment"],
    }),
    getPrivateCallStandbyPreJoin: builder.query({
      query: (body) => ({
        url: "api/v1/private-calls/standby/pre-join",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getPrivateCallStandbyJoin: builder.query({
      query: (body) => ({
        url: "api/v1/private-calls/standby/join",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getPrivateCallStandbyStart: builder.query({
      query: (body) => ({
        url: "api/v1/private-calls/standby/start",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getPrivateCallStandbyEnd: builder.query({
      query: (body) => ({
        url: "api/v1/private-calls/standby/end",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getPrivateCallStandbyDetails: builder.query({
      query: () => ({
        url: "api/v1/private-calls/standby/details",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getPrivateCallStandbyKeepAlive: builder.query({
      query: (param) => ({
        url: `api/v1/private-calls/standby/keep-alive${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    kickPrivateCallUser: builder.mutation({
      query: (body) => ({
        url: "api/v1/private-calls/standby/kick",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getPrivateCallRoomLeave: builder.query({
      query: (body) => ({
        url: "api/v1/private-calls/room/leave",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getPrivateCallRoomKeepAlive: builder.query({
      query: (param) => ({
        url: `api/v1/private-calls/room/keep-alive${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    postPrivateCallRoomGiftRequest: builder.mutation({
      query: (body) => ({
        url: "api/v1/private-calls/room/gift_request",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    postPrivateCallRoomCoinRequest: builder.mutation({
      query: (body) => ({
        url: "api/v1/private-calls/room/coin_request",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    postPrivateCallRoomChat: builder.mutation({
      query: (body) => ({
        url: "api/v1/private-calls/room/chat",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getPrivateCallTipItems: builder.query({
      query: () => ({
        url: "api/v1/tp/settings",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      providesTags: ["PrivateCallTipItems"],
    }),
    updatePrivateCallTipItems: builder.mutation({
      query: (body) => ({
        url: "api/v1/tp/settings",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
      invalidatesTags: ["PrivateCallTipItems"],
    }),
    getPrivateCallTipItemsMenu: builder.query({
      query: (param) => ({
        url: `api/v1/tp/menu${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    postPrivateCallSendTip: builder.mutation({
      query: (body) => ({
        url: "api/v1/tp/send",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    postPrivateCallRequestTip: builder.mutation({
      query: (body) => ({
        url: "api/v1/tp/request",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    postPrivateCallRequestReject: builder.mutation({
      query: (body) => ({
        url: "api/v1/tp/request/reject",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    postPrivateCallRequestAccept: builder.mutation({
      query: (body) => ({
        url: "api/v1/tp/request/accept",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    postPrivateCallSendTipCoin: builder.mutation({
      query: (body) => ({
        url: "api/v1/tp/send/coin",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getPrivateCallRequestDetails: builder.query({
      query: (param) => ({
        url: `api/v1/private-calls/requests/details${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getPrivateCallSummary: builder.query({
      query: (param) => ({
        url: `api/v1/private-calls/summary${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    postPrivateCallRating: builder.mutation({
      query: (body) => ({
        url: "api/v1/private-calls/ratings",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    claimPrivateCallDiamonds: builder.mutation({
      query: (body) => ({
        url: "api/v1/private-calls/standby/claim",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getPrivateCallUserMeta: builder.query({
      query: (body) => ({
        url: "api/v1/private-calls/user-meta",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getPrivateCallPreRequest: builder.query({
      query: (body) => ({
        url: "api/v1/private-calls/pre-requests",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getPrivateCallRequest: builder.query({
      query: (body) => ({
        url: "api/v1/private-calls/requests",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    rejectPrivateCallRequest: builder.mutation({
      query: (body) => ({
        url: "api/v1/private-calls/requests/reject",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    acceptPrivateCallRequest: builder.mutation({
      query: (body) => ({
        url: "api/v1/private-calls/requests/accept",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    cancelPrivateCallRequest: builder.mutation({
      query: (body) => ({
        url: "api/v1/private-calls/requests/cancel",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getPrivateCallRequestRefresh: builder.query({
      query: (param) => ({
        url: `api/v1/private-calls/requests/refresh${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    joinPrivateCallWaitingRoom: builder.query({
      query: (body) => ({
        url: "api/v1/private-calls/waiting_rooms/join",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    joinPrivateCallRoom: builder.mutation({
      query: (body) => ({
        url: "api/v1/private-calls/room/join",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getStreamerCenter: builder.query({
      query: () => ({
        url: "api/v1/streamers/streamer-center",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getStreamerStatsMonthly: builder.query({
      query: (body) => ({
        url: "api/v1/streamers/stats/monthly",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    getLastStreamResult: builder.query({
      query: () => ({
        url: "api/v1/live-streaming/channels/streamer/last_stream_result",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getVideoCallSearch: builder.query({
      query: (body) => ({
        url: "api/v1/search",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
      providesTags: ["VideoCallSearch"],
    }),
    getInboxSingleConversation: builder.query({
      query: (param) => ({
        url: `api/v1/messaging/single_conversations${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    postSingleConversation: builder.mutation({
      query: ({ id, body }) => ({
        url: `api/v1/messaging/single_conversations${id ? `/${id}` : ""}`,
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getVideoCallHistory: builder.query({
      query: (param) => ({
        url: `api/v1/private-calls/history${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getPrivateCallSettings: builder.query({
      query: () => ({
        url: "api/v1/private-calls/settings",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      providesTags: ["PrivateCallSettings", "ChargedMessage"],
    }),
    updatePrivateCallSettings: builder.mutation({
      query: (body) => ({
        url: "api/v1/private-calls/settings",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
      invalidatesTags: ["ChargedMessage"],
    }),
    getCardDetails: builder.query({
      query: () => ({
        url: "api/v1/payment_gateways/securionpay/cards",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    submitNewCardToken: builder.mutation({
      query: (body) => ({
        url: "api/v1/payment_gateways/securionpay/card",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    getProductCoins: builder.query({
      query: () => ({
        url: "api/v1/products/coin",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getProductGifts: builder.query({
      query: () => ({
        url: "api/v1/products/gift",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getPaymentGatewayList: builder.query({
      query: (param) => ({
        url: `api/v1/payments/gateways${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    purchaseProduct: builder.mutation({
      query: (body) => ({
        url: "api/v1/payments/product/purchase",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
      invalidatesTags: ["CoinBalance"],
    }),
    postPaymentTag: builder.mutation({
      query: (body) => ({
        url: "api/v1/payments/tag",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
      invalidatesTags: ["CoinPayment"],
    }),
    getToken: builder.query({
      query: () => ({
        url: "api/v1/token_passthrough/get-token",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    verifyToken: builder.query({
      query: (param) => ({
        url: `api/v1/token_passthrough/verify${param}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    // Interaction
    getNotificationsList: builder.query({
      query: (param) => ({
        url: `api/v1/notifications${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getNotificationsCount: builder.query({
      query: () => ({
        url: "api/v1/notifications/count",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getMyFavoritesList: builder.query({
      query: (param) => ({
        url: `api/v1/interactions/favorites${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getFavoritedMeList: builder.query({
      query: (param) => ({
        url: `api/v1/interactions/favorited_me${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getViewedMeList: builder.query({
      query: (param) => ({
        url: `api/v1/interactions/viewed_me${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getPhotoPermissionList: builder.query({
      query: (param) => ({
        url: `api/v1/interactions/photo_permissions${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getBlockedUsersList: builder.query({
      query: (param) => ({
        url: `api/v1/interactions/blocked_users${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    blockOrUnblockUser: builder.mutation({
      query: (body) => ({
        url: "api/v1/interactions/blocked_users",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    favoriteUnfavoriteUser: builder.mutation({
      query: (body) => ({
        url: "api/v1/interactions/favorites",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    photoPermissionRequest: builder.mutation({
      query: (body) => ({
        url: "api/v1/interactions/photo_permissions",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    photoPermissionApprove: builder.mutation({
      query: (body) => ({
        url: "api/v1/interactions/photo_permissions/approve",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    photoPermissionRevoke: builder.mutation({
      query: (body) => ({
        url: "api/v1/interactions/photo_permissions/revoke",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    // Livestream
    createLivestream: builder.mutation({
      query: (body) => ({
        url: "api/v1/live-streaming/channels/streamer/create",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    getLivestreamingCoAnchorView: builder.query({
      query: (param) => ({
        url: `api/v1/live-streaming/co-anchor/view${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    enterLivestreamingCoAnchor: builder.mutation({
      query: (body) => ({
        url: "api/v1/live-streaming/co-anchor/enter",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    livestreamingCoAnchorKeepAlive: builder.mutation({
      query: (body) => ({
        url: "api/v1/live-streaming/co-anchor/keep-alive",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    livestreamingCoAnchorPkRoundStats: builder.query({
      query: (param) => ({
        url: `api/v1/live-streaming/co-anchor/pk/round-stats${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    livestreamingCoAnchorPkDetails: builder.query({
      query: (param) => ({
        url: `api/v1/live-streaming/co-anchor/pk/details${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getLivestreamingCoAnchorViewersList: builder.query({
      query: (param) => ({
        url: `api/v1/live-streaming/co-anchor/viewers-list${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
      // providesTags: ["FollowUnfollowUser"]
    }),
    // Gaming
    getGamingList: builder.query({
      query: () => ({
        url: "api/v1/gaming/list",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    initiateGame: builder.query({
      query: (url) => ({
        url,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getGamingLeaderboard: builder.query({
      query: (param) => ({
        url: `api/v1/gaming/leaderboards${param ?? ""}`,
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getGamingBanners: builder.query({
      query: () => ({
        url: "api/v1/gaming/banners",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    // Payment
    initXenopayPayment: builder.mutation({
      query: (body) => ({
        url: "api/v1/payment_gateways/xeno/init",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    checkXenopayStatus: builder.mutation({
      query: (body) => ({
        url: "api/v1/payment_gateways/xeno/check",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
  }),
});

// dev-spi-auth.sgrbk.com // spi.sgrbk.com
export const authSpiApi = createApi({
  reducerPath: "authSpiApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env["REACT_APP_ALT_SPI_API"] }),
  endpoints: (builder) => ({
    authSignupV2: builder.mutation({
      query: (body) => ({
        url: "api/v2/auth/sign-up",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body,
      }),
    }),
    getAuthSignupOptions: builder.query({
      query: () => ({
        url: "api/v2/journey/options",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getJourneyQuestions: builder.query({
      query: () => ({
        url: "api/v2/journey/questions",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    getJourney: builder.query({
      query: () => ({
        url: "api/v2/journey",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
    updateJourney: builder.mutation({
      query: (body) => ({
        url: "api/v2/journey/update",
        method: "PATCH",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
    }),
    completeJourney: builder.mutation({
      query: (body) => ({
        url: "api/v2/journey/complete",
        method: "POST",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        body: body,
      }),
      invalidatesTags: ["ProfileCompletion"],
    }),
    restartJourney: builder.query({
      query: () => ({
        url: "api/v2/journey/restart",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
  }),
});

// payments.sgrbk.com
export const paymentApi = createApi({
  reducerPath: "paymentApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env["REACT_APP_PAYMENT_API"] }),
  endpoints: (builder) => ({
    subscribeSecurionPay: builder.mutation({
      query: (body) => ({
        url: "api/securionpay/subscribe",
        method: "POST",
        headers: API_CONFIG_HEADERS.COM_HEADERS,
        body: body,
      }),
    }),
  }),
});

export const {
  useSignupMutation,
  useSigninMutation,
  usePasswordRecoveryMutation,
  useUpdateAccountEmailMutation,
  useResendAccountEmailVerificationMutation,
  useUpdateAccountEmailIdentityVerificationMutation,
  useLazyResendEmailVerificationQuery,
  useLazyGetBadgeCountQuery,
  useSendSupportTicketMutation,
  useLazyGetPwaPaymentsQuery,
  useLazyGetPromotionsQuery,
  useApplyPromoCodeMutation,
  useRemovePromoCodeMutation,
  useLazyGetPhoneValidationQuery,
  useSendPhoneVerificationCodeMutation,
  useVerifyVerificationCodeMutation,
  useCreateCustomVariableMutation,
  useLazyGetCustomVariableQuery,
  useUpdateCustomVariableMutation,
  useDeleteCustomVariableMutation,
} = authApi;
export const {
  useLogoutMutation,
  useLazyGetAioQuery,
  useLazyGetProfilePwaQuery,
  useLazyGetProfileCheckQuery,
  useLazyGetSearchUserProfileQuery,
  useUpdateProfileMutation,
  useUpdateProfilePhotoMutation,
  useReportUserMutation,
  useLazyGetSearchBannersQuery,
  useLazyGetSearchMasqueradeBannersQuery,
  useLazyGetAllSettingsQuery,
  useUpdateNotificationSettingsMutation,
  useUpdatePasswordMutation,
  useUpdateEmailActivationMutation,
  useUpdateAccountDeactivationMutation,
  useClearConversationMutation,
} = comApi;
export const {
  useAuthSigninMutation,
  useAuthSignupMutation,
  useAuthSocialMutation,
  useLazyAuthSignoutQuery,
  useAuthRefreshTokenMutation,
  useForgotPasswordMutation,
  useVerifyForgotPasswordMutation,
  useInitiateMobileVerificationMutation,
  useVerifyMobileVerificationMutation,
  useInitiateEmailVerificationMutation,
  useVerifyEmailVerificationMutation,
  useInitiateFacialVerificationMutation,
  useVerifyFacialVerificationMutation,
  useInitiateManualVerificationMutation,
  useLazyGetProfileQuery,
  useLazyGetProfileCompletionQuery,
  useLazyGetUserProfileQuery,
  useLazyGetUserAioQuery,
  useUpdateUserProfileMutation,
  useUpdateEditUserProfileMutation,
  useUploadUserPhotoMutation,
  useUpdateUserPhotoMutation,
  useUpdateUserLocationMutation,
  useLazyGetInvitationUserStatsQuery,
  useLazyGetPrivateCallFreeMinutesQuery,
  useLazyGetLevellingTasksQuery,
  useClaimLevellingTasksMutation,
  useLazyGetVirtualCurrencyCoinBalanceQuery,
  useLazyGetDatingRecentlyActiveQuery,
  useLazyGetDatingNearestQuery,
  useLazyGetDatingNewestQuery,
  useLazyGetDatingCollegeQuery,
  useLazyGetDatingPremiumQuery,
  useLazyGetDatingMasqueradePartyQuery,
  useLazyGetDatingFilteredQuery,
  useLazyGetTorListQuery,
  useSaveTorListMutation,
  usePostFollowUserMutation,
  usePostUnfollowUserMutation,
  useLazyGetFollowersListQuery,
  useLazyGetFollowingListQuery,
  useLazyGetLifetimeGiftersListQuery,
  usePostRemoveFollowersMutation,
  useLazyGetLivestreamingChannelsQuery,
  useLazyGetLivestreamingChannelQuery,
  usePostLivestreamingEnterChannelMutation,
  usePostLivestreamingEnteredChannelMutation,
  usePostLivestreamingLeaveChannelMutation,
  useLazyGetLivestreamingEndChannelQuery,
  usePostLivestreamingChannelChatMutation,
  useLazyGetLivestreamingBannersQuery,
  useLazyGetLivestreamingTopBannersQuery,
  useLazyGetLivestreamingPlayerFloatingBannersQuery,
  useLazyGetLivestreamingGiftersListQuery,
  useLazyGetLivestreamingFansLeaderboardQuery,
  useLazyGetLivestreamingViewersListQuery,
  useLazyGetLivestreamingViewersKeepAliveQuery,
  useLazyGetLivestreamingContestListQuery,
  useLazyGetLivestreamingContestLeaderboardQuery,
  useLazyGetLivestreamingLeaderboardQuery,
  useLazyGetLivestreamingPkLeaderboardQuery,
  useLazyGetLivestreamingPkQuery,
  useLazyGetLivestreamingReportCriteriaQuery,
  useLazyGetLivestreamingUserMetaQuery,
  useLazyGetLivestreamingChannelRefreshInfoQuery,
  usePostReportLivestreamingChannelMutation,
  useLazyGetPrivateCallContestListQuery,
  useLazyGetPrivateCallContestLeaderboardQuery,
  useLazyGetPrivateCallsLeaderboardQuery,
  useLazyGetPrivateCallsBannersQuery,
  useLazyGetPrivateCallStandbyPreJoinQuery,
  useLazyGetPrivateCallStandbyJoinQuery,
  useLazyGetPrivateCallStandbyStartQuery,
  useLazyGetPrivateCallStandbyEndQuery,
  useLazyGetPrivateCallStandbyDetailsQuery,
  useLazyGetPrivateCallStandbyKeepAliveQuery,
  useKickPrivateCallUserMutation,
  useLazyGetPrivateCallRoomLeaveQuery,
  useLazyGetPrivateCallRoomKeepAliveQuery,
  usePostPrivateCallRoomGiftRequestMutation,
  usePostPrivateCallRoomCoinRequestMutation,
  usePostPrivateCallRoomChatMutation,
  useLazyGetPrivateCallTipItemsQuery,
  useUpdatePrivateCallTipItemsMutation,
  useLazyGetPrivateCallTipItemsMenuQuery,
  usePostPrivateCallSendTipMutation,
  usePostPrivateCallRequestTipMutation,
  usePostPrivateCallRequestRejectMutation,
  usePostPrivateCallRequestAcceptMutation,
  usePostPrivateCallSendTipCoinMutation,
  useLazyGetPrivateCallRequestDetailsQuery,
  useLazyGetPrivateCallSummaryQuery,
  usePostPrivateCallRatingMutation,
  useClaimPrivateCallDiamondsMutation,
  useLazyGetPrivateCallUserMetaQuery,
  useLazyGetPrivateCallPreRequestQuery,
  useLazyGetPrivateCallRequestQuery,
  useRejectPrivateCallRequestMutation,
  useAcceptPrivateCallRequestMutation,
  useCancelPrivateCallRequestMutation,
  useLazyGetPrivateCallRequestRefreshQuery,
  useLazyJoinPrivateCallWaitingRoomQuery,
  useJoinPrivateCallRoomMutation,
  useLazyGetStreamerCenterQuery,
  useLazyGetStreamerStatsMonthlyQuery,
  useLazyGetLastStreamResultQuery,
  useLazyGetVideoCallSearchQuery,
  useLazyGetInboxSingleConversationQuery,
  usePostSingleConversationMutation,
  useLazyGetVideoCallHistoryQuery,
  useLazyGetPrivateCallSettingsQuery,
  useUpdatePrivateCallSettingsMutation,
  useLazyGetCardDetailsQuery,
  useSubmitNewCardTokenMutation,
  useLazyGetProductCoinsQuery,
  useLazyGetProductGiftsQuery,
  useLazyGetPaymentGatewayListQuery,
  usePurchaseProductMutation,
  usePostPaymentTagMutation,
  useLazyGetTokenQuery,
  useLazyVerifyTokenQuery,
  // Interaction
  useLazyGetNotificationsListQuery,
  useLazyGetNotificationsCountQuery,
  useLazyGetMyFavoritesListQuery,
  useLazyGetFavoritedMeListQuery,
  useLazyGetViewedMeListQuery,
  useLazyGetPhotoPermissionListQuery,
  useLazyGetBlockedUsersListQuery,
  useBlockOrUnblockUserMutation,
  useFavoriteUnfavoriteUserMutation,
  usePhotoPermissionRequestMutation,
  usePhotoPermissionApproveMutation,
  usePhotoPermissionRevokeMutation,
  // Livestream
  useCreateLivestreamMutation,
  useLazyGetLivestreamingCoAnchorViewQuery,
  useEnterLivestreamingCoAnchorMutation,
  useLivestreamingCoAnchorKeepAliveMutation,
  useLazyLivestreamingCoAnchorPkRoundStatsQuery,
  useLazyLivestreamingCoAnchorPkDetailsQuery,
  useLazyGetLivestreamingCoAnchorViewersListQuery,
  // Gaming
  useLazyGetGamingListQuery,
  useLazyInitiateGameQuery,
  useLazyGetGamingLeaderboardQuery,
  useLazyGetGamingBannersQuery,
  // Payment
  useInitXenopayPaymentMutation,
  useCheckXenopayStatusMutation,
} = spiApi;
export const {
  useAuthSignupV2Mutation,
  useLazyGetAuthSignupOptionsQuery,
  useLazyGetJourneyQuestionsQuery,
  useLazyGetJourneyQuery,
  useUpdateJourneyMutation,
  useCompleteJourneyMutation,
  useLazyRestartJourneyQuery,
} = authSpiApi;
export const { useSubscribeSecurionPayMutation } = paymentApi;
