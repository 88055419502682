const searchConst = {
  categoryTab: [
    {
      jsonTitle: "search.tabs.filtered",
      title: "Filtered",
      value: "filtered",
      toApi: "filtered",
    },
    {
      jsonTitle: "search.tabs.recently_active",
      title: "Recently Active",
      value: "recently-active",
      toApi: "recently_active",
    },
    {
      jsonTitle: "search.tabs.nearest",
      title: "Nearest",
      value: "nearest",
      toApi: "nearest",
    },
    {
      jsonTitle: "search.tabs.newest",
      title: "Newest",
      value: "newest",
      toApi: "newest",
    },
    {
      jsonTitle: "search.tabs.lgbtq",
      title: "Recently Active",
      value: "lgbtq-recently-active",
      toApi: "lgbtq_recently_active",
    },
    {
      jsonTitle: "search.tabs.college",
      title: "College",
      value: "college",
      toApi: "college",
    },
    {
      jsonTitle: "search.tabs.masquerade_party",
      title: "Masquerade Party",
      value: "masquerade-party",
      toApi: "masquerade_party",
    },
    {
      jsonTitle: "search.tabs.premium",
      title: "Premium",
      value: "premium",
      toApi: "premium",
    },
  ],
  // categoryTab: {
  //   filtered: {
  //     jsonTitle: "search.tabs.filtered",
  //     title: "Filtered",
  //     value: "filtered",
  //     toApi: "filtered",
  //   },
  //   recentlyActive: {
  //     jsonTitle: "search.tabs.recently_active",
  //     title: "Recently Active",
  //     value: "recently-active",
  //     toApi: "recently_active",
  //   },
  //   nearby: {
  //     jsonTitle: "search.tabs.nearest",
  //     title: "Nearby",
  //     value: "nearby",
  //     toApi: "nearest",
  //   },
  //   newest: {
  //     jsonTitle: "search.tabs.newest",
  //     title: "Newest",
  //     value: "newest",
  //     toApi: "newest",
  //   },
  //   college: {
  //     jsonTitle: "search.tabs.college",
  //     title: "College",
  //     value: "college",
  //     toApi: "college",
  //   },
  //   premium: {
  //     jsonTitle: "search.tabs.premium",
  //     title: "Premium",
  //     value: "premium",
  //     toApi: "premium",
  //   },
  //   masqueradeParty: {
  //     jsonTitle: "search.tabs.masquerade_party",
  //     title: "Masquerade Party",
  //     value: "masquerade-party",
  //     toApi: "masquerade_party",
  //   },
  // },
  filters: {
    basicFilters: [
      {
        jsonTitle: "filter.has_photos",
        title: "Has Photos",
        value: "has-photos",
        fromStore: null,
      },
      {
        jsonTitle: "filter.premium",
        title: "Premium",
        value: "premium",
        fromStore: null,
      },
      {
        jsonTitle: "filter.unviewed",
        title: "Unviewed",
        vlaue: "unviewed",
        fromStore: null,
      },
      {
        jsonTitle: "filter.favorited",
        title: "Favorited",
        value: "favorited",
        fromStore: null,
      },
    ],
    ageRange: { min: 18, max: 60 },
    heightRange: { min: 130, max: 220 },
    advancedFilters: [
      {
        jsonTitle: "filter.lifestyle",
        title: "Lifestyle",
        value: "lifestyle",
      },
      {
        jsonTitle: "filter.body_type",
        title: "Body Type",
        value: "bodyType",
      },
      {
        jsonTitle: "filter.ethnicity",
        title: "Ethnicity",
        value: "ethnicity",
      },
      {
        jsonTitle: "filter.eye_color",
        title: "Eye Color",
        value: "eyeColor",
      },
      {
        jsonTitle: "filter.hair_color",
        title: "Hair Color",
        value: "hairColor",
      },
      {
        jsonTitle: "filter.smoking",
        title: "Smoking",
        value: "smoking",
      },
      {
        jsonTitle: "filter.drinking",
        title: "Drinking",
        value: "drinking",
      },
      {
        jsonTitle: "filter.relationship_status",
        title: "Relationship Status",
        value: "relationshipStatus",
      },
      {
        jsonTitle: "filter.education",
        title: "Education",
        value: "education",
      },
    ],
    additionalFilters: [
      {
        jsonTitle: "filter.terms_of_relationship",
        title: "Terms of Relationship",
        value: "termsOfRelationship",
      },
    ],
  },
  searchBody: {
    filters: {
      search_term: null,
      photos: null,
      viewed: null,
      unviewed: null,
      favorited: null,
      favorited_me: null,
      age: {
        min: null,
        max: null,
      },
      height: null,
      children: null,
      ethnicity: {
        asian: "asian",
        black: "black",
        caucasian: "caucasian",
        eastIndian: "east-indian",
        latin: "latin",
        middleEastern: "middle-eastern",
        mixed: "mixed",
        nativeAmerican: "native-american",
        other: "other",
        pacificIslander: "pacific-islander",
      },
      tor: null,
      lifestyle: {
        high: "high",
        minimal: "minimal",
        moderate: "moderate",
        negotiable: "negotiable",
        practical: "practical",
        substantial: "substantial",
      },
      relationship: {
        divorced: "divorced",
        marriedButLooking: "married-but-looking",
        separated: "separated",
        single: "single",
        widowed: "widowed",
      },
      body_type: {
        fullOrOverweight: "full-or-overweight",
        other: "other",
        aFewExtraPounds: "a-few-extra-pounds",
        athletic: "athletic",
        slim: "slim",
        average: "average",
      },
      drinking: {
        heavyDrinker: "heavy-drinker",
        lightDrinker: "light-drinker",
        nonDrinker: "non-drinker",
        socialDrinker: "social-drinker",
      },
      smoking: {
        heavySmoker: "heavy-smoker",
        lightSmoker: "light-smoker",
        nonSmoker: "non-smoker",
      },
      education: {
        associatesDegree: "associates-degree",
        bachelorsDegree: "bachelors-degree",
        college: "college",
        graduateDegree: "graduate-degree",
        highSchool: "high-school",
        phdOrPostDoctoral: "phd-or-post-doctoral",
        someCollege: "some-college",
      },
      eye_color: {
        black: "black",
        blue: "blue",
        brown: "brown",
        green: "green",
        grey: "grey",
        hazel: "hazel",
        other: "other",
      },
      hair_color: {
        auburn: "auburn",
        black: "black",
        blonde: "blonde",
        darkBrown: "dark-brown",
        grey: "grey",
        lightBrown: "light-brown",
        other: "other",
        red: "red",
        white: "white",
      },
    },
  },
  searchFilterType: {
    full: "full",
    dialog: "dialog",
  },
  userCardDisplay: {
    grid: "grid",
    list: "list",
  },
  endCardType: {
    generic: "generic",
    filtered: "filtered",
    videoCallSb: "video-call-sb",
    videoCallSd: "video-call-sd",
  },
};

export default searchConst;
