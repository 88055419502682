// General
import "./premium-view.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetDatingPremiumQuery,
  useLazyGetProfileQuery,
} from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
import searchConst from "../../../../../const/searchConst";
import userConst from "../../../../../const/userConst";
import userCardConst from "../../../../../const/userCardConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updatePremiumLists,
  resetPremiumLists,

  // Pagination Functions
  updatePremiumPagination,

  // Utility Functions
  updateRestartJourneyCounter,
} from "../../../../../redux/store/searchStore";
// React Infinite Scroller
import InfiniteScroll from "react-infinite-scroller";
// react-simple-pull-to-refresh
import PullToRefresh from "react-simple-pull-to-refresh";
// Material UI
import { Divider, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import useGetUserProfileStatus from "../../../../utility/custom-hooks/useGetUserProfileStatus-hook";
// Components
import UserCardManager from "../../../../utility/manager/user-card-manager/user-card-manager";
import PremiumEmptyList from "../premium-empty-list/premium-empty-list";
import SkeletonUserCardList from "../../../../shared/skeleton-user-card-list/skeleton-user-card-list";
import Spinner from "../../../../shared/elements/spinner/spinner";
import ResultEndCard from "../../../../shared/elements/result-end-card/result-end-card";

const PremiumView = () => {
  // API variables
  const [
    getDatingPremium,
    {
      data: getDatingPremiumData,
      error: getDatingPremiumErrorData,
      isFetching: getDatingPremiumFetching,
      isLoading: getDatingPremiumLoading,
      isSuccess: getDatingPremiumSuccess,
      isError: getDatingPremiumError,
    },
  ] = useLazyGetDatingPremiumQuery();
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // General variables
  const [firstLoad, setFirstLoad] = useState(true);
  const [isApiLoading, setIsApiLoading] = useState(false);

  // Render variables
  let premiumListView = <div></div>;

  // Redux variables
  const selectedCategoryTab = useSelector(
    (state) => state.search.selectedCategoryTab
  );
  const selectedView = useSelector((state) => state.search.selectedView);
  const premiumLists = useSelector((state) => state.search.premiumLists);
  const premiumPagination = useSelector(
    (state) => state.search.premiumPagination
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  const body = {
    size: 16,
    location: {},
    sorting: "recently_active",
    filters: {
      premium_only: true,
    },
  };

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getProfileStatus = useGetUserProfileStatus();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Dating Premium API Response
  useEffect(() => {
    if (getDatingPremiumFetching || getDatingPremiumLoading) {
    } else if (getDatingPremiumSuccess) {
      dispatch(
        updatePremiumPagination(
          getDatingPremiumData?.data?.pagination?.next_cursor
        )
      );

      switch (getDatingPremiumData?.status) {
        case 0:
        case 1:
        case -1:
          if (getDatingPremiumData?.data?.results?.length > 0) {
            setFirstLoad(false);

            // Use to block infiniteScrollFunc from running into infinite loop
            setIsApiLoading(false);

            dispatch(updatePremiumLists(getDatingPremiumData?.data?.results));
          }
          break;
        default:
          break;
      }
    } else if (getDatingPremiumError) {
      if (getDatingPremiumErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getDatingPremiumFetching,
    getDatingPremiumLoading,
    getDatingPremiumSuccess,
    getDatingPremiumError,
  ]);

  // Lifecycle | Check for update | Selected Category Tab
  useEffect(() => {
    if (selectedCategoryTab === "premium") {
      if (getProfileData?.data?.membership_type !== "free") {
        if (premiumLists.length <= 0) {
          if (premiumPagination) {
            setFirstLoad(false);
          } else {
            setFirstLoad(true);
          }

          getDatingPremium(
            {
              param: premiumPagination
                ? `?pagination_token=${premiumPagination}`
                : null,
              body,
            },
            false
          );
        } else {
          setFirstLoad(false);
        }
      }
    }
  }, [selectedCategoryTab]);

  // Utility Functions
  const getUserCardType = (user) => {
    if (user?.type === "gaming") {
      return "gaming";
    } else if (
      user?.user?.live_stream?.co_anchor &&
      user?.user?.live_stream?.live_status === "paused"
    ) {
      return "co_anchor";
    } else {
      return user?.type;
    }
  };

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (!isApiLoading && premiumPagination) {
      setIsApiLoading(true);
      dispatch(updateRestartJourneyCounter());
      getDatingPremium(
        {
          param: premiumPagination
            ? `?pagination_token=${premiumPagination}`
            : null,
          body,
        },
        false
      );
    }
  };

  // Pull To Refresh Functions
  const pullToRefreshFunc = () => {
    dispatch(resetPremiumLists());
    dispatch(updatePremiumPagination(null));
    setFirstLoad(true);

    return new Promise((resolve) => {
      resolve(getDatingPremium({ param: null, body }));
    });
  };

  // Render Functions | Dating Premium List Data
  if (selectedCategoryTab === "premium") {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      if (getProfileData?.status === 0) {
        if (
          getProfileData?.data?.membership_type ===
          userConst.membershipType.free
        ) {
          premiumListView = <PremiumEmptyList />;
        } else {
          if (premiumLists?.length > 0) {
            premiumListView = (
              <div className={`infinite-scroller-container ${selectedView}`}>
                {premiumLists?.map((user, index) => (
                  <div className="list-container" key={index}>
                    <UserCardManager
                      // Config
                      type={getUserCardType(user)}
                      // User View
                      cardView={selectedView}
                      id={user?.user?.id}
                      idInt={user?.user?.id_int}
                      username={user?.user?.username}
                      age={user?.user?.appearance?.age}
                      gender={user?.user?.gender}
                      location={
                        user?.user?.location?.display_address ||
                        user?.user?.location?.city ||
                        user?.user?.location?.state
                      }
                      membershipType={user?.user?.membership_type}
                      verifiedProfile={user?.user?.is_verified_profile}
                      verifiedCaller={user?.user?.verified_caller}
                      levellingBadge={user?.user?.levelling_profile?.badge}
                      livestreamingAchievementBadge={
                        user?.user?.live_streaming_achievements?.chat_badges_set
                      }
                      specialBadges={user?.user?.special_badges}
                      profilePhoto={user?.user?.profile_photo?.original_photo}
                      profileDecorations={user?.user?.profile_decorations}
                      publicPhotos={user?.user?.public_photos?.map((photo) => {
                        return {
                          photoUrl: photo?.original_photo,
                        };
                      })}
                      privatePhotos={user?.user?.private_photos?.map(
                        (photo) => {
                          return {
                            photoUrl: photo?.original_photo,
                            thumbnailData: photo?.thumbnail_data,
                            isSensitive: photo?.is_sensitive,
                          };
                        }
                      )}
                      torTags={user?.user?.tor_tags}
                      datingStyle={user?.user?.relationship?.dating_style}
                      profileAchievementBadge={
                        user?.user?.live_streaming_achievements?.profile_badges
                      }
                      aboutMe={user?.user?.aboutme}
                      aboutMeReadStatus={user?.user?.aboutme_read_status}
                      lookingFor={user?.user?.interest}
                      tagline={user?.user?.tagline}
                      annualIncome={user?.user?.occupation?.annual_income}
                      networth={user?.user?.occupation?.net_worth}
                      occupation={user?.user?.occupation?.occupation}
                      lifestyle={user?.user?.occupation?.lifestyle_budget}
                      datingBudget={user?.user?.relationship?.dating_budget}
                      education={user?.user?.occupation?.education}
                      relationship={
                        user?.user?.relationship?.relationship_status
                      }
                      smoking={user?.user?.habit?.smoking}
                      drinking={user?.user?.habit?.drinking}
                      alcoholPreference={user?.user?.habit?.alcohol}
                      height={user?.user?.appearance?.height}
                      bodyType={user?.user?.appearance?.body_type}
                      ethnicity={user?.user?.appearance?.ethnicity}
                      eyeColor={user?.user?.appearance?.eye_color}
                      hairColor={user?.user?.appearance?.hair_color}
                      children={user?.user?.relationship?.children}
                      // About Me
                      personalityTraits={
                        user?.user?.personality?.personality_traits
                      }
                      personalityType={
                        user?.user?.personality?.personality_type
                      }
                      starSign={user?.user?.personality?.star_sign}
                      dominantOrSubmissive={
                        user?.user?.personality?.dominant_or_submissive
                      }
                      fitnessLevel={user?.user?.habit?.fitness_level}
                      hobbies={user?.user?.habit?.hobbies}
                      // User Utility
                      profileStatus={getProfileStatus(user?.user)}
                      privatePhotoAbleToRequest={
                        user?.user?.interaction?.private_photo_requested ===
                        false
                          ? true
                          : false
                      }
                      privatePhotoRequested={
                        user?.user?.interaction?.private_photo_requested
                      }
                      privatePhotoApproval={
                        user?.user?.interaction?.private_photo_approval
                      }
                      requestedMyPrivatePhoto={
                        user?.user?.interaction?.requested_my_private_photo
                      }
                      approvedMyPrivatePhoto={
                        user?.user?.interaction?.approved_my_private_photo
                      }
                      isOnline={user?.user?.activity?.is_online}
                      isBlocked={user?.user?.interaction?.is_blocked}
                      isSilentInteraction={
                        user?.user?.interaction?.should_silent_interactions
                      }
                      isFavorited={user?.user?.interaction?.is_favorited}
                      isCallEnabled={
                        user?.user?.private_call_settings?.enable_calls &&
                        user?.user?.private_call_settings
                          ?.system_private_calls_enabled
                      }
                      isSugarbaby={isDaddy}
                      role={user?.user?.role}
                      interest={user?.user?.interest}
                      isLgbtqInterested={
                        user?.user?.relationship?.is_lgbtq_interested
                      }
                      // Livestream
                      livestreamData={user?.user?.live_stream}
                      channelId={user?.user?.live_stream?.channel_id}
                      livestreamerId={user?.user?.id}
                      followerCount={
                        user?.user?.live_streaming_meta?.follower_count
                      }
                      viewerCount={user?.user?.live_stream?.viewer_count}
                      diamondCount={
                        user?.user?.live_streaming_meta
                          ?.lifetime_diamonds_earned
                      }
                      isFollowing={
                        user?.user?.live_streaming_meta?.is_following
                      }
                      // Card Utility
                      cardStatus={userCardConst.cardStatus.default}
                      // Gaming View
                      backgroundImage={user?.logo}
                    />

                    {selectedView === searchConst.userCardDisplay.list && (
                      <Divider />
                    )}
                  </div>
                ))}
              </div>
            );
          } else if (
            firstLoad &&
            (getDatingPremiumFetching || getDatingPremiumLoading)
          ) {
            premiumListView = (
              <SkeletonUserCardList
                numberOfCards={4}
                selectedView={selectedView}
              />
            );
          }
        }
      }
    } else if (getProfileError) {
    }

    return (
      <div
        id="search-card-container-premium-view-subcomponent"
        className={premiumLists?.length < 0 ? "full-height" : ""}
      >
        <PullToRefresh
          className="pull-to-refresh-container"
          onRefresh={pullToRefreshFunc}
          refreshingContent={t("common.release")}
          pullingContent={t("common.pull_to_refresh")}
          isPullable={!getDatingPremiumFetching && !getDatingPremiumLoading}
        >
          <div className="premium-list-container">
            <InfiniteScroll
              pageStart={0}
              loadMore={infiniteScrollFunc}
              hasMore={premiumPagination ? true : false}
              loader={<Spinner key={0} />}
              useWindow={false}
              getScrollParent={() => document.getElementById("search-page")}
              threshold={isMobile ? 10 : 50}
            >
              {premiumListView}

              {premiumLists?.length > 0 && !premiumPagination && (
                <ResultEndCard />
              )}
            </InfiniteScroll>
          </div>
        </PullToRefresh>
      </div>
    );
  }
};

export default PremiumView;
