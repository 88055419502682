// General
import "./lifestyle-budget.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateJourneyMutation,
} from "../../../../services/data.service";
// Static Data
import userConst from "../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLifestyle,
  updateDatingBudget,
  updateJourneyNavigationPassthrough,
  updateJourneyDataPassthrough,
  updateRegistrationNavigationData,
} from "../../../../redux/store/registrationStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const LifestyleBudgetDetails = () => {
  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateJourney,
    {
      data: updateJourneyData,
      error: updateJourneyErrorData,
      isLoading: updateJourneyLoading,
      isSuccess: updateJourneySuccess,
      isError: updateJourneyError,
    },
  ] = useUpdateJourneyMutation();
  const [
    skipJourney,
    {
      data: skipJourneyData,
      error: skipJourneyErrorData,
      isLoading: skipJourneyLoading,
      isSuccess: skipJourneySuccess,
      isError: skipJourneyError,
    },
  ] = useUpdateJourneyMutation();

  // Redux variables
  const lifestyle = useSelector((state) => state.registration.lifestyle);
  const datingBudget = useSelector((state) => state.registration.datingBudget);
  const wantToBe = useSelector((state) => state.signup.wantToBe);
  const skippableJourney = useSelector(
    (state) => state.registration.skippableJourney
  );
  const registrationNavigationData = useSelector(
    (state) => state.registration.registrationNavigationData
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | updateJourney API Response
  useEffect(() => {
    if (updateJourneyLoading) {
    } else if (updateJourneySuccess) {
      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (updateJourneyError) {
      const warningToast = {
        message: updateJourneyErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(warningToast));
    }
  }, [updateJourneyLoading, updateJourneySuccess, updateJourneyError]);

  // Lifecycle | Check for update | skipJourney API Response
  useEffect(() => {
    if (skipJourneyLoading) {
    } else if (skipJourneySuccess) {
      const updatedItems = registrationNavigationData?.map((item, i) => ({
        ...item,
        skipped: i === 3 ? true : i > 3 ? false : item.skipped,
      }));
      dispatch(updateRegistrationNavigationData(updatedItems));

      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (skipJourneyError) {
    }
  }, [skipJourneyLoading, skipJourneySuccess, skipJourneyError]);

  // Event Handlers | Button
  const onSelectLifestyle = (lifestyle) => {
    dispatch(updateLifestyle(lifestyle));
  };
  const onSelectDatingBudget = (dating) => {
    dispatch(updateDatingBudget(dating));
  };
  const onNextPage = () => {
    if (!isFormValid()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.4-62.4.1",
      },
    });

    let obj = {};

    if (wantToBe === userConst.wantToBe.sugardaddy.value) {
      obj = {
        journey_step: "4",
        journey_data: {
          profile: {
            dating_budget: datingBudget?.id,
          },
        },
      };
    } else if (wantToBe === userConst.wantToBe.sugarbaby.value) {
      obj = {
        journey_step: "4",
        journey_data: {
          profile: {
            lifestyle: lifestyle?.id,
          },
        },
      };
    }

    updateJourney(obj);
  };
  const onSkip = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.4-62.4.2",
      },
    });

    const obj = {
      journey_step: "4",
      journey_data: {
        looking_for: {},
      },
    };
    skipJourney(obj);
  };

  // Utility Functions
  const isFormValid = () => {
    if (wantToBe === userConst.wantToBe.sugardaddy.value) {
      return datingBudget && !updateJourneyLoading;
    } else if (wantToBe === userConst.wantToBe.sugarbaby.value) {
      return lifestyle && !updateJourneyLoading;
    }
  };
  const getLifestyleData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "lifestyle"
    )?.selections;
  };
  const getDatingBudgetData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "dating_budget"
    )?.selections;
  };

  return (
    <div id="details-signup-lifestyle-budget-details-subcomponent">
      <div className="top-container">
        {wantToBe === userConst.wantToBe.sugardaddy.value ? (
          <div className="lifestyle-budget-details-label">
            <Trans
              i18nKey={"registration.dating_budget.title"}
              components={{ br: <br /> }}
            />
          </div>
        ) : (
          <div className="lifestyle-budget-details-label">
            <Trans
              i18nKey={"registration.lifestyle_budget.title"}
              components={{ br: <br /> }}
            />
          </div>
        )}

        <div className="lifestyle-budget-description-label">
          {wantToBe === userConst.wantToBe.sugardaddy.value
            ? t("registration.dating_budget.description")
            : t("registration.lifestyle_budget.description")}
        </div>

        {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
          <Spinner />
        ) : (
          <div className="lifestyle-budget-body-container">
            {wantToBe === userConst.wantToBe.sugardaddy.value
              ? getDatingBudgetData()?.map((item, index) => (
                  <div
                    className={`lifestyle-item ${
                      isMobile ? "partial-width" : "fit-content"
                    } ${item?.id === datingBudget?.id ? "selected" : ""}`}
                    onClick={() => onSelectDatingBudget(item)}
                    key={index}
                  >
                    {item?.display_locale}
                  </div>
                ))
              : getLifestyleData()?.map((item, index) => (
                  <div
                    className={`lifestyle-item ${
                      isMobile ? "partial-width" : "fix-width"
                    } ${item?.id === lifestyle?.id ? "selected" : ""}`}
                    onClick={() => onSelectLifestyle(item)}
                    key={index}
                  >
                    {item?.display_locale}
                  </div>
                ))}
          </div>
        )}
      </div>

      <div className="bottom-container">
        <div
          className={`continue-button ${
            isMobile ? "full-width" : "fit-content"
          } ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onNextPage}
        >
          {updateJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.continue")
          )}
        </div>

        <div className="skip-button" onClick={onSkip}>
          {skipJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.skip")
          )}
        </div>
      </div>
    </div>
  );
};

export default LifestyleBudgetDetails;
