// General
import "./choose-your-role-dialog.scss";
import { useState, forwardRef } from "react";
// Static Data
import userConst from "../../../../const/userConst";
import user from "../../../../enums/user";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateGender,
  updateWantToBe,
  updateLookingFor,
} from "../../../../redux/store/signupStore";
import { updateChooseYourRoleDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, Button, Divider, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";

const ChooseYourRoleDialog = () => {
  // General variables
  const [localWantToBe, setLocalWantToBe] = useState(null);

  // Redux variables
  const chooseYourRoleDialog = useSelector(
    (state) => state.dialog.chooseYourRoleDialog
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | Button
  const onSelectWantToBe = (wantToBe) => {
    setLocalWantToBe(wantToBe);
  };

  // Event Handlers | Button
  const onContinue = () => {
    dispatch(updateGender(user.gender.LGBTQ));
    dispatch(updateWantToBe(localWantToBe));
    dispatch(updateLookingFor(user.lookingFor.SUGARDADDY));

    onCloseDialog();
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    if (
      reason === "backdropClick" ||
      reason === "clickaway" ||
      reason === "escapeKeyDown"
    )
      return;

    setLocalWantToBe(null);
    dispatch(updateChooseYourRoleDialog(false));
  };

  // Utility Functions
  const isFormValid = () => {
    return localWantToBe;
  };

  return (
    <Dialog
      className={
        isMobile ? "custom-bottom-sheet-dialog" : "custom-radius25-dialog"
      }
      fullScreen={isMobile ? true : false}
      open={chooseYourRoleDialog}
      TransitionComponent={Transition}
      maxWidth="xs"
      onClose={onCloseDialog}
    >
      <div id="choose-your-role-dialog">
        <div className="dialog-header">
          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        <div className="padding-container">
          <div className="choose-your-role-label">
            {t("signup.choose_your_role")}
          </div>

          <div className="want-to-be-selection-container">
            <div
              className="sugar-daddy-container"
              onClick={() =>
                onSelectWantToBe(userConst.wantToBe.sugardaddy.value)
              }
            >
              <div className="description-container">
                <div
                  className={`sugar-daddy-title ${
                    localWantToBe === userConst.wantToBe.sugarbaby.value
                      ? "selected-title"
                      : ""
                  }`}
                >
                  {t("landing.drawer.sugar_daddy")}
                </div>

                <div
                  className={`sugar-daddy-subtitle ${
                    localWantToBe === userConst.wantToBe.sugarbaby.value
                      ? "selected-title"
                      : ""
                  }`}
                >
                  {t("signup.help_looking_for_gay_subtitle")}
                </div>

                <div
                  className={`sugar-daddy-description ${
                    localWantToBe === userConst.wantToBe.sugarbaby.value
                      ? "selected-description"
                      : ""
                  }`}
                >
                  {t("signup.sugardaddy_description")}
                </div>
              </div>

              <div className="selected-container">
                <div className="selected-outer-ring">
                  <div
                    className={`selected-inner-ring ${
                      localWantToBe === userConst.wantToBe.sugardaddy.value
                        ? "selected"
                        : ""
                    }`}
                  ></div>
                </div>
              </div>
            </div>

            <Divider />

            <div
              className="sugar-baby-container"
              onClick={() =>
                onSelectWantToBe(userConst.wantToBe.sugarbaby.value)
              }
            >
              <div className="description-container">
                <div
                  className={`sugar-baby-title ${
                    localWantToBe === userConst.wantToBe.sugardaddy.value
                      ? "selected-title"
                      : ""
                  }`}
                >
                  {t("landing.drawer.sugar_baby")}
                </div>

                <div
                  className={`sugar-baby-subtitle ${
                    localWantToBe === userConst.wantToBe.sugardaddy.value
                      ? "selected-title"
                      : ""
                  }`}
                >
                  {t("signup.help_looking_for_lesbian_subtitle")}
                </div>

                <div
                  className={`sugar-baby-description ${
                    localWantToBe === userConst.wantToBe.sugardaddy.value
                      ? "selected-description"
                      : ""
                  }`}
                >
                  {t("signup.sugarbaby_description")}
                </div>
              </div>

              <div className="selected-container">
                <div className="selected-outer-ring">
                  <div
                    className={`selected-inner-ring ${
                      localWantToBe === userConst.wantToBe.sugarbaby.value
                        ? "selected"
                        : ""
                    }`}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-container">
          <Button
            className={`continue-button ${
              localWantToBe ? "" : "disabled-button"
            }`}
            variant="contained"
            disabled={!isFormValid()}
            disableElevation
            onClick={onContinue}
          >
            {t("common.continue")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ChooseYourRoleDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
