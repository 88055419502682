// General
import "./looking-for.scss";
// Static Data
import editProfileConst from "../../../../../const/editProfileConst";
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateUpdateProfileDialogView } from "../../../../../redux/store/editProfileStore";
import {
  updateRestartRegistrationDialog,
  updateUpdateProfileFullscreenDialog,
} from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Divider } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// i18next
import { useTranslation } from "react-i18next";

const LookingFor = (props) => {
  const { mode = utilityConst.editProfileMode.mobile } = props;

  // Redux variables
  const lookingForAgeRange = useSelector(
    (state) => state.editProfile.lookingForAgeRange
  );
  const lookingForLocation = useSelector(
    (state) => state.editProfile.lookingForLocation
  );
  const lookingForEthnicityRace = useSelector(
    (state) => state.editProfile.lookingForEthnicityRace
  );
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const profileRequirementPopup = useSelector(
    (state) => state.profile.profileRequirementPopup
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | Button
  const onEditSection = (section) => {
    if (profileCompletionPercentage < profileRequirementPopup) {
      dispatch(updateRestartRegistrationDialog(true));
    } else {
      switch (section) {
        case "ageRange":
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-25-25.8",
            },
          });

          // dispatch(
          //   updateProfileCompletionState({
          //     name: "lookingFor",
          //     slide: returnMaxLookingFor(),
          //   })
          // );
          // dispatch(updateUpdateProfileFullscreenTab(0));
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.lookingForAgeRange
            )
          );
          dispatch(updateUpdateProfileFullscreenDialog(true));
          break;
        case "location":
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-25-25.9",
            },
          });

          // dispatch(
          //   updateProfileCompletionState({
          //     name: "lookingFor",
          //     slide: returnMaxLookingFor(),
          //   })
          // );
          // dispatch(updateUpdateProfileFullscreenTab(1));
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.lookingForLocation
            )
          );
          dispatch(updateUpdateProfileFullscreenDialog(true));
          break;
        case "ethnicity":
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-25-25.10",
            },
          });

          // dispatch(
          //   updateProfileCompletionState({
          //     name: "lookingFor",
          //     slide: returnMaxLookingFor(),
          //   })
          // );
          // dispatch(updateUpdateProfileFullscreenTab(2));
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.lookingForEthnicityRace
            )
          );
          dispatch(updateUpdateProfileFullscreenDialog(true));
          break;
        default:
          break;
      }
    }
  };

  // Utility Functions
  const returnMaxLookingFor = () => {
    return 3;
  };

  if (mode === utilityConst.editProfileMode.mobile) {
    return (
      <div id="mobile-edit-profile-looking-for-subcomponent">
        <div className="looking-for-label-container">
          <div className="looking-for-label">{t("profile.looking_for")}</div>
        </div>

        <div className="looking-for-description">
          {t("profile.looking_for_description")}
        </div>

        <div className="looking-for-topic-container">
          {/* Age Range */}
          <div
            className="topic-container"
            onClick={() => onEditSection("ageRange")}
          >
            <div className="left-container">
              <div className="add-topic-label">
                {lookingForAgeRange?.min !== 0 && lookingForAgeRange?.max !== 0
                  ? t("profile.age_range")
                  : t("profile.select_age_range")}
              </div>

              <div className="add-topic-description">
                {lookingForAgeRange?.min !== 0 &&
                lookingForAgeRange?.max !== 0 ? (
                  <div className="add-topic-description-min-max">
                    {lookingForAgeRange?.min} - {lookingForAgeRange?.max}{" "}
                    {t("common.years_old")}
                  </div>
                ) : (
                  <div className="add-topic-description-text">
                    {t("profile.specify_what_age_range")}
                  </div>
                )}
              </div>
            </div>

            <div className="right-container">
              {lookingForAgeRange?.min !== 0 &&
              lookingForAgeRange?.max !== 0 ? (
                <ChevronRightIcon className="right-icon" />
              ) : (
                <AddRoundedIcon className="right-icon" />
              )}
            </div>
          </div>

          {/* Location */}
          {false && (
            <div
              className="topic-container"
              onClick={() => onEditSection("location")}
            >
              <div className="left-container">
                <div className="add-topic-label">
                  {lookingForLocation?.length > 0
                    ? t("profile.locations")
                    : t("profile.select_locations")}
                </div>
                <div className="add-topic-description">
                  {lookingForLocation?.length > 0 ? (
                    <div className="add-topic-description-location-container">
                      {lookingForLocation?.map((location, index) => (
                        <div
                          className="add-topic-location-container"
                          key={index}
                        >
                          {location?.location_data?.formatted_address}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="add-topic-description-text">
                      {t("profile.specify_locations")}
                    </div>
                  )}
                </div>
              </div>

              <div className="right-container">
                {lookingForLocation?.length > 0 ? (
                  <ChevronRightIcon className="right-icon" />
                ) : (
                  <AddRoundedIcon className="right-icon" />
                )}
              </div>
            </div>
          )}

          {/* Ethnicity & Race */}
          <div
            className="topic-container"
            onClick={() => onEditSection("ethnicity")}
          >
            <div className="left-container">
              <div className="add-topic-label">
                {lookingForEthnicityRace?.length > 0
                  ? t("profile.ethnicity_race")
                  : t("profile.select_ethnicity_race")}
              </div>
              <div className="add-topic-description">
                {lookingForEthnicityRace?.length > 0 ? (
                  <div className="add-topic-description-ethnicity-container">
                    {lookingForEthnicityRace?.map((ethnicity, index) => (
                      <div
                        className="add-topic-ethnicity-container"
                        key={index}
                      >
                        {ethnicity?.ethnicity?.display_locale}
                        {ethnicity?.ethnicity?.id !== 701 && ethnicity?.race
                          ? `, ${ethnicity?.race?.display_locale}`
                          : ""}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="add-topic-description-text">
                    {t("profile.specify_ethnicity_race")}
                  </div>
                )}
              </div>
            </div>

            <div className="right-container">
              {lookingForEthnicityRace?.length > 0 ? (
                <ChevronRightIcon className="right-icon" />
              ) : (
                <AddRoundedIcon className="right-icon" />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (mode === utilityConst.editProfileMode.desktop) {
    return (
      <div id="desktop-edit-profile-looking-for-subcomponent">
        <div className="looking-for-static-label-container">
          <div className="looking-for-label">{t("profile.looking_for")}</div>
          <Divider className="short-divider" />
        </div>

        <div className="looking-for-description">
          {t("profile.looking_for_description")}
        </div>

        <div className="looking-for-topic-container">
          {/* Age Range */}
          <div
            className="topic-container"
            onClick={() => onEditSection("ageRange")}
          >
            <div className="left-container">
              <div className="add-topic-label">
                {lookingForAgeRange?.min !== 0 && lookingForAgeRange?.max !== 0
                  ? t("profile.age_range")
                  : t("profile.select_age_range")}
              </div>

              <div className="add-topic-description">
                {lookingForAgeRange?.min !== 0 &&
                lookingForAgeRange?.max !== 0 ? (
                  <div className="add-topic-description-min-max">
                    {lookingForAgeRange?.min} - {lookingForAgeRange?.max}{" "}
                    {t("common.years_old")}
                  </div>
                ) : (
                  <div className="add-topic-description-text">
                    {t("profile.specify_what_age_range")}
                  </div>
                )}
              </div>
            </div>

            <div className="right-container">
              {lookingForAgeRange?.min !== 0 &&
              lookingForAgeRange?.max !== 0 ? (
                <ChevronRightIcon className="right-icon" />
              ) : (
                <AddRoundedIcon className="right-icon" />
              )}
            </div>
          </div>

          {/* Location */}
          {false && (
            <div
              className="topic-container"
              onClick={() => onEditSection("location")}
            >
              <div className="left-container">
                <div className="add-topic-label">
                  {lookingForLocation?.length > 0
                    ? t("profile.locations")
                    : t("profile.select_locations")}
                </div>
                <div className="add-topic-description">
                  {lookingForLocation?.length > 0 ? (
                    <div className="add-topic-description-location-container">
                      {lookingForLocation?.map((location, index) => (
                        <div
                          className="add-topic-location-container"
                          key={index}
                        >
                          {location?.location_data?.formatted_address}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="add-topic-description-text">
                      {t("profile.select_locations")}
                    </div>
                  )}
                </div>
              </div>

              <div className="right-container">
                {lookingForLocation?.length > 0 ? (
                  <ChevronRightIcon className="right-icon" />
                ) : (
                  <AddRoundedIcon className="right-icon" />
                )}
              </div>
            </div>
          )}

          {/* Ethnicity & Race */}
          <div
            className="topic-container"
            onClick={() => onEditSection("ethnicity")}
          >
            <div className="left-container">
              <div className="add-topic-label">
                {lookingForEthnicityRace?.length > 0
                  ? t("profile.ethnicity_race")
                  : t("profile.select_ethnicity_race")}
              </div>
              <div className="add-topic-description">
                {lookingForEthnicityRace?.length > 0 ? (
                  <div className="add-topic-description-ethnicity-container">
                    {lookingForEthnicityRace?.map((ethnicity, index) => (
                      <div
                        className="add-topic-ethnicity-container"
                        key={index}
                      >
                        {ethnicity?.ethnicity?.display_locale}
                        {ethnicity?.ethnicity?.id !== 701 && ethnicity?.race
                          ? `, ${ethnicity?.race?.display_locale}`
                          : ""}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="add-topic-description-text">
                    {t("profile.select_ethnicity_race")}
                  </div>
                )}
              </div>
            </div>

            <div className="right-container">
              {lookingForEthnicityRace?.length > 0 ? (
                <ChevronRightIcon className="right-icon" />
              ) : (
                <AddRoundedIcon className="right-icon" />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default LookingFor;
