// General
import "./filtered-view.scss";
import { useState, useEffect, useRef } from "react";
// Services
import { useLazyGetDatingFilteredQuery } from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
import searchConst from "../../../../../const/searchConst";
import userCardConst from "../../../../../const/userCardConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateFilteredLists,
  resetFilteredLists,

  // Pagination Functions
  updateFilteredPagination,

  // Utility Functions
  updateRestartJourneyCounter,
} from "../../../../../redux/store/searchStore";
// React Infinite Scroller
import InfiniteScroll from "react-infinite-scroller";
// react-simple-pull-to-refresh
import PullToRefresh from "react-simple-pull-to-refresh";
// Material UI
import { Divider, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import useGetUserProfileStatus from "../../../../utility/custom-hooks/useGetUserProfileStatus-hook";
// Components
import UserCardManager from "../../../../utility/manager/user-card-manager/user-card-manager";
import EmptyList from "../empty-list/empty-list";
import SkeletonUserCardList from "../../../../shared/skeleton-user-card-list/skeleton-user-card-list";
import Spinner from "../../../../shared/elements/spinner/spinner";
import ResultEndCard from "../../../../shared/elements/result-end-card/result-end-card";

const FilteredView = () => {
  // API variables
  const [
    getDatingSearch,
    {
      data: getDatingSearchData,
      error: getDatingSearchErrorData,
      isFetching: getDatingSearchFetching,
      isLoading: getDatingSearchLoading,
      isSuccess: getDatingSearchSuccess,
      isError: getDatingSearchError,
    },
  ] = useLazyGetDatingFilteredQuery();

  // General variables
  const [firstLoad, setFirstLoad] = useState(true);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const isMounted = useRef(false);

  // Render variables
  let filteredListView = <div></div>;

  // Redux variables
  const selectedCategoryTab = useSelector(
    (state) => state.search.selectedCategoryTab
  );
  const selectedView = useSelector((state) => state.search.selectedView);
  const filteredLists = useSelector((state) => state.search.filteredLists);
  const filteredPagination = useSelector(
    (state) => state.search.filteredPagination
  );
  const searchBody = useSelector((state) => state.search.searchBody);
  const selectedFilters = useSelector(
    (state) => state.searchFilter.selectedFilters
  );
  const triggerSearchFilteredPassthrough = useSelector(
    (state) => state.searchFilter.triggerSearchFilteredPassthrough
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // Param variables
  const body = {
    size: 16,
    location:
      searchBody?.location?.lat && searchBody?.location?.lng
        ? searchBody?.location
        : {},
    sorting: "recently_active",
    filters: Object.fromEntries(
      Object.entries(searchBody?.filters).filter(
        ([key, value]) => value !== null && value !== false && value !== ""
      )
    ),
  };

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getProfileStatus = useGetUserProfileStatus();

  // Lifecycle | Check for update | Dating Filtered API Response
  useEffect(() => {
    if (getDatingSearchFetching || getDatingSearchLoading) {
    } else if (getDatingSearchSuccess) {
      dispatch(
        updateFilteredPagination(
          getDatingSearchData?.data?.pagination?.next_cursor
        )
      );

      switch (getDatingSearchData?.status) {
        case 0:
        case 1:
        case -1:
          if (getDatingSearchData?.data?.results?.length > 0) {
            setFirstLoad(false);

            // Use to block infiniteScrollFunc from running into infinite loop
            setIsApiLoading(false);

            dispatch(updateFilteredLists(getDatingSearchData?.data?.results));
          }
          break;
        default:
          break;
      }
    } else if (getDatingSearchError) {
      if (getDatingSearchErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getDatingSearchFetching,
    getDatingSearchLoading,
    getDatingSearchSuccess,
    getDatingSearchError,
  ]);

  // Lifecycle | Check for update | triggerSearchFilteredPassthrough & selectedCategoryTab
  useEffect(() => {
    if (selectedCategoryTab === "filtered") {
      if (filteredLists?.length <= 0) {
        if (filteredPagination) {
          setFirstLoad(false);
        } else {
          setFirstLoad(true);
        }

        getDatingSearch(
          {
            param: null,
            body,
          },
          false
        );
      } else {
        setFirstLoad(false);
      }
    }
  }, [selectedCategoryTab]);

  // Lifecycle | Check for update | triggerSearchFilteredPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (triggerSearchFilteredPassthrough) {
        if (selectedCategoryTab === "filtered") {
          dispatch(resetFilteredLists());
          dispatch(updateFilteredPagination(null));
          setFirstLoad(true);

          getDatingSearch(
            {
              param: null,
              body,
            },
            false
          );
        }
      }
    } else {
      isMounted.current = true;
    }
  }, [triggerSearchFilteredPassthrough, selectedCategoryTab]);

  // Utility Functions
  const getUserCardType = (user) => {
    if (user?.type === "gaming") {
      return "gaming";
    } else if (
      user?.user?.live_stream?.co_anchor &&
      user?.user?.live_stream?.live_status === "paused"
    ) {
      return "co_anchor";
    } else {
      return user?.type;
    }
  };

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (!isApiLoading && filteredPagination) {
      setIsApiLoading(true);
      dispatch(updateRestartJourneyCounter());
      getDatingSearch(
        {
          param: filteredPagination
            ? `?pagination_token=${filteredPagination}`
            : null,
          body,
        },
        true
      );
    }
  };

  // Pull To Refresh Functions
  const pullToRefreshFunc = () => {
    dispatch(resetFilteredLists());
    dispatch(updateFilteredPagination(null));
    setFirstLoad(true);

    return new Promise((resolve) => {
      resolve(getDatingSearch({ param: null, body }));
    });
  };

  // Render Functions | Dating Filtered List Data
  if (selectedCategoryTab === "filtered") {
    if (filteredLists?.length > 0) {
      filteredListView = (
        <div className={`infinite-scroller-container ${selectedView}`}>
          {filteredLists?.map((user, index) => (
            <div className="list-container" key={index}>
              <UserCardManager
                // Config
                type={getUserCardType(user)}
                // User View
                cardView={selectedView}
                id={user?.user?.id}
                idInt={user?.user?.id_int}
                username={user?.user?.username}
                age={user?.user?.appearance?.age}
                gender={user?.user?.gender}
                location={
                  user?.user?.location?.display_address ||
                  user?.user?.location?.city ||
                  user?.user?.location?.state
                }
                membershipType={user?.user?.membership_type}
                verifiedProfile={user?.user?.is_verified_profile}
                verifiedCaller={user?.user?.verified_caller}
                levellingBadge={user?.user?.levelling_profile?.badge}
                livestreamingAchievementBadge={
                  user?.user?.live_streaming_achievements?.chat_badges_set
                }
                specialBadges={user?.user?.special_badges}
                profilePhoto={user?.user?.profile_photo?.original_photo}
                profileDecorations={user?.user?.profile_decorations}
                publicPhotos={user?.user?.public_photos?.map((photo) => {
                  return {
                    photoUrl: photo?.original_photo,
                  };
                })}
                privatePhotos={user?.user?.private_photos?.map((photo) => {
                  return {
                    photoUrl: photo?.original_photo,
                    thumbnailData: photo?.thumbnail_data,
                    isSensitive: photo?.is_sensitive,
                  };
                })}
                torTags={user?.user?.tor_tags}
                datingStyle={user?.user?.relationship?.dating_style}
                profileAchievementBadge={
                  user?.user?.live_streaming_achievements?.profile_badges
                }
                aboutMe={user?.user?.aboutme}
                aboutMeReadStatus={user?.user?.aboutme_read_status}
                lookingFor={user?.user?.interest}
                tagline={user?.user?.tagline}
                annualIncome={user?.user?.occupation?.annual_income}
                networth={user?.user?.occupation?.net_worth}
                occupation={user?.user?.occupation?.occupation}
                lifestyle={user?.user?.occupation?.lifestyle_budget}
                datingBudget={user?.user?.relationship?.dating_budget}
                education={user?.user?.occupation?.education}
                relationship={user?.user?.relationship?.relationship_status}
                smoking={user?.user?.habit?.smoking}
                drinking={user?.user?.habit?.drinking}
                alcoholPreference={user?.user?.habit?.alcohol}
                height={user?.user?.appearance?.height}
                bodyType={user?.user?.appearance?.body_type}
                ethnicity={user?.user?.appearance?.ethnicity}
                eyeColor={user?.user?.appearance?.eye_color}
                hairColor={user?.user?.appearance?.hair_color}
                children={user?.user?.relationship?.children}
                // About Me
                personalityTraits={user?.user?.personality?.personality_traits}
                personalityType={user?.user?.personality?.personality_type}
                starSign={user?.user?.personality?.star_sign}
                dominantOrSubmissive={
                  user?.user?.personality?.dominant_or_submissive
                }
                fitnessLevel={user?.user?.habit?.fitness_level}
                hobbies={user?.user?.habit?.hobbies}
                // User Utility
                profileStatus={getProfileStatus(user?.user)}
                privatePhotoAbleToRequest={
                  user?.user?.interaction?.private_photo_requested === false
                    ? true
                    : false
                }
                privatePhotoRequested={
                  user?.user?.interaction?.private_photo_requested
                }
                privatePhotoApproval={
                  user?.user?.interaction?.private_photo_approval
                }
                requestedMyPrivatePhoto={
                  user?.user?.interaction?.requested_my_private_photo
                }
                approvedMyPrivatePhoto={
                  user?.user?.interaction?.approved_my_private_photo
                }
                isOnline={user?.user?.activity?.is_online}
                isBlocked={user?.user?.interaction?.is_blocked}
                isSilentInteraction={
                  user?.user?.interaction?.should_silent_interactions
                }
                isFavorited={user?.user?.interaction?.is_favorited}
                isCallEnabled={
                  user?.user?.private_call_settings?.enable_calls &&
                  user?.user?.private_call_settings
                    ?.system_private_calls_enabled
                }
                isSugarbaby={isDaddy}
                role={user?.user?.role}
                interest={user?.user?.interest}
                isLgbtqInterested={
                  user?.user?.relationship?.is_lgbtq_interested
                }
                // Livestream
                livestreamData={user?.user?.live_stream}
                channelId={user?.user?.live_stream?.channel_id}
                livestreamerId={user?.user?.id}
                followerCount={user?.user?.live_streaming_meta?.follower_count}
                viewerCount={user?.user?.live_stream?.viewer_count}
                diamondCount={
                  user?.user?.live_streaming_meta?.lifetime_diamonds_earned
                }
                isFollowing={user?.user?.live_streaming_meta?.is_following}
                // Card Utility
                cardStatus={userCardConst.cardStatus.default}
                // Gaming View
                backgroundImage={user?.logo}
              />

              {selectedView === searchConst.userCardDisplay.list && <Divider />}
            </div>
          ))}
        </div>
      );
    } else if (
      firstLoad &&
      (getDatingSearchFetching || getDatingSearchLoading)
    ) {
      filteredListView = (
        <SkeletonUserCardList numberOfCards={4} selectedView={selectedView} />
      );
    } else if (!getDatingSearchFetching && !filteredPagination) {
      filteredListView = null;
    } else {
      filteredListView = <EmptyList />;
    }

    return (
      <div
        id="search-card-container-filtered-view-subcomponent"
        className={filteredLists?.length <= 0 ? "full-height" : ""}
      >
        <PullToRefresh
          className="pull-to-refresh-container"
          onRefresh={pullToRefreshFunc}
          refreshingContent={t("common.release")}
          pullingContent={t("common.pull_to_refresh")}
          isPullable={!getDatingSearchFetching && !getDatingSearchLoading}
        >
          <div className="filtered-list-container">
            <InfiniteScroll
              pageStart={0}
              loadMore={infiniteScrollFunc}
              hasMore={filteredPagination ? true : false}
              loader={<Spinner key={0} />}
              useWindow={false}
              getScrollParent={() => document.getElementById("search-page")}
              threshold={isMobile ? 10 : 50}
            >
              {filteredListView}

              {!getDatingSearchFetching && !filteredPagination && (
                <ResultEndCard
                  type={
                    filteredLists?.length <= 10 && selectedFilters?.length > 3
                      ? searchConst.endCardType.filtered
                      : searchConst.endCardType.generic
                  }
                />
              )}
            </InfiniteScroll>
          </div>
        </PullToRefresh>
      </div>
    );
  }
};

export default FilteredView;
