// General
import "./whats-a-role-dialog.scss";
import { useEffect, forwardRef } from "react";
// Static Data
import user from "../../../../enums/user";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateWhatsARoleDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";

const WhatsARoleDialog = () => {
  // Redux variables
  const whatsARoleDialog = useSelector(
    (state) => state.dialog.whatsARoleDialog
  );
  const gender = useSelector((state) => state.signup.gender);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!whatsARoleDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-WhatsARole-Dialog",
      },
    });
  }, [whatsARoleDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateWhatsARoleDialog(false));
  };

  return (
    <Dialog
      className={`${
        isMobile ? "custom-bottom-sheet-dialog" : "custom-radius25-dialog"
      }`}
      fullScreen={isMobile ? true : false}
      open={whatsARoleDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="whats-a-role-dialog">
        <div className="dialog-header">
          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        <div className="padding-container">
          <div className="title">{t("signup.definition")}</div>

          <div className="sugardaddy-mommy-container">
            <div className="sugardaddy-mommy-label">
              {gender === user.gender.LGBTQ
                ? t("common.gay")
                : t("signup.help_looking_for_sd_sm")}
            </div>

            <div className="description">
              {gender === user.gender.LGBTQ
                ? t("signup.help_looking_for_gay")
                : t("signup.help_looking_for_sd_sm_desc")}
            </div>
          </div>

          <div className="sugarbaby-container">
            <div className="sugarbaby-label">
              {gender === user.gender.LGBTQ
                ? t("common.lesbian")
                : t("signup.help_looking_for_sb")}
            </div>

            <div className="description">
              {gender === user.gender.LGBTQ
                ? t("signup.help_looking_for_lesbian")
                : t("signup.help_looking_for_sb_desc")}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default WhatsARoleDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
