const user = {
  gender: {
    MAN: "man",
    WOMAN: "woman",
    NON_BINARY: "non-binary",
    LGBTQ: "lgbtq",
  },
  lookingFor: {
    SUGARBABY: "sugarbaby",
    SUGARMOMMY: "sugardaddy",
    SUGARDADDY: "sugardaddy",
    MALE_SUGARBABY: "sugarbaby",
  },
};

export default user;
