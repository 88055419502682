// General
import "./profile-looking-for.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../../services/data.service";
// Static Data
import editProfileConst from "../../../../../const/editProfileConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLookingForAgeRange,
  updateLookingForLocation,
  updateLookingForEthnicityRace,
  updateUpdateProfileDialogView,
} from "../../../../../redux/store/editProfileStore";
import { updateProfileCompletionPercentage } from "../../../../../redux/store/profileStore";
import {
  updateUpdateProfileFullscreenDialog,
  updateRestartRegistrationDialog,
} from "../../../../../redux/store/dialogStore";
// i18next
import { useTranslation } from "react-i18next";

const ProfileLookingFor = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const lookingForAgeRange = useSelector(
    (state) => state.userCard.lookingForAgeRange
  );
  const lookingForLocation = useSelector(
    (state) => state.userCard.lookingForLocation
  );
  const lookingForEthnicityRace = useSelector(
    (state) => state.userCard.lookingForEthnicityRace
  );
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const profileRequirementPopup = useSelector(
    (state) => state.profile.profileRequirementPopup
  );
  const isSelf = useSelector((state) => state.userCard.isSelf);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | getProfileData
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      // dispatch(
      //   updateProfileCompletionPercentage(
      //     getProfileData?.data?.profile_progress?.summary?.progress
      //   )
      // );
      dispatch(
        updateLookingForAgeRange({
          min: getProfileData?.data?.looking_fors?.age?.min,
          max: getProfileData?.data?.looking_fors?.age?.max,
        })
      );
      dispatch(
        updateLookingForLocation(getProfileData?.data?.looking_fors?.locations)
      );
      const temp = getProfileData?.data?.looking_fors?.ethnicity?.map(
        (item) => ({
          ethnicity: {
            id: item?.option?.id,
            locale: item?.option?.locale,
            display_locale: item?.option?.locale,
          },
          race: item?.sub_option
            ? {
                id: item?.sub_option?.id,
                locale: item?.sub_option?.locale,
                display_locale: item?.sub_option?.locale,
              }
            : null,
        })
      );
      dispatch(updateLookingForEthnicityRace(temp));
    } else if (getProfileError) {
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Event Handlers | Button
  const onEditSection = (section) => {
    if (isSelf) return;

    if (profileCompletionPercentage < profileRequirementPopup) {
      dispatch(updateRestartRegistrationDialog(true));
    } else {
      switch (section) {
        case editProfileConst.profileDialogView.lookingForAgeRange:
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.lookingForAgeRange
            )
          );
          break;
        case editProfileConst.profileDialogView.lookingForLocation:
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.lookingForLocation
            )
          );
          break;
        case editProfileConst.profileDialogView.lookingForEthnicityRace:
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.lookingForEthnicityRace
            )
          );
          break;
        default:
          break;
      }

      dispatch(updateUpdateProfileFullscreenDialog(true));
    }
  };

  if (
    (lookingForAgeRange?.age?.min && lookingForAgeRange?.age?.max) ||
    lookingForLocation?.length > 0 ||
    lookingForEthnicityRace?.length > 0
  ) {
    return (
      <div id="profile-looking-for-subcomponent">
        <div className="padding-container">
          <div className="looking-for-label">
            {t("profile.what_im_looking_for")}
          </div>

          <div className="looking-for-container">
            <div
              className={`looking-for-item-container ${
                isSelf ? "non-clickable" : ""
              }`}
              onClick={() => onEditSection("lookingForAgeRange")}
            >
              {lookingForAgeRange?.min}-{lookingForAgeRange?.max}{" "}
              {t("common.years_old")}
            </div>

            {false &&
              lookingForLocation?.map((location, index) => (
                <div
                  className={`looking-for-item-container  ${
                    isSelf ? "non-clickable" : ""
                  }`}
                  onClick={() => onEditSection("lookingForLocation")}
                  key={index}
                >
                  {location?.location_data?.formatted_address}
                </div>
              ))}

            {lookingForEthnicityRace?.map((ethnicity, index) => (
              <div
                className={`looking-for-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("lookingForEthnicityRace")}
                key={index}
              >
                {ethnicity?.ethnicity?.locale}{" "}
                {ethnicity?.race ? `, ${ethnicity?.race?.locale}` : ""}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
};

export default ProfileLookingFor;
