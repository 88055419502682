// General
import "./profile-alcohol-preference.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../../services/data.service";
// Static Data
import editProfileConst from "../../../../../const/editProfileConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateAlcoholPreference,
  updateUpdateProfileDialogView,
} from "../../../../../redux/store/editProfileStore";
import {
  updateRestartRegistrationDialog,
  updateUpdateProfileFullscreenDialog,
} from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hook
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const ProfileAlcoholPreference = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const alcoholPreference = useSelector(
    (state) => state.userCard.alcoholPreference
  );
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const profileRequirementPopup = useSelector(
    (state) => state.profile.profileRequirementPopup
  );
  const isSelf = useSelector((state) => state.userCard.isSelf);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | getProfile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      // dispatch(
      //   updateProfileCompletionPercentage(
      //     getProfileData?.data?.profile_progress?.summary?.progress
      //   )
      // );
      dispatch(updateAlcoholPreference(getProfileData?.data?.habit?.alcohol));
    } else if (getProfileError) {
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Event Handlers | Button
  const onEditSection = (section) => {
    if (isSelf) return;

    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-65-65.1`,
      },
    });

    if (profileCompletionPercentage < profileRequirementPopup) {
      dispatch(updateRestartRegistrationDialog(true));
    } else {
      // dispatch(
      //   updateProfileCompletionState({
      //     name: "personalInformation",
      //     slide: returnMaxPersonalInformation(),
      //   })
      // );

      switch (section) {
        case "alcoholPreference":
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.alcoholPreference
            )
          );
          // dispatch(updateUpdateProfileFullscreenTab(6));
          break;
        default:
          break;
      }

      dispatch(updateUpdateProfileFullscreenDialog(true));
    }
  };

  // Utility Functions
  const getAlcoholIcon = (alcoholId) => {
    switch (alcoholId) {
      case 70:
        return getIcon("redWineIcon", "alcohol-icon");
      case 71:
        return getIcon("whiteWineIcon", "alcohol-icon");
      case 72:
        return getIcon("champagneIcon", "alcohol-icon");
      case 73:
        return getIcon("cognacIcon", "alcohol-icon");
      case 74:
        return getIcon("tequilaIcon", "alcohol-icon");
      case 75:
        return getIcon("beerIcon", "alcohol-icon");
      case 76:
        return getIcon("cocktailIcon", "alcohol-icon");
      case 77:
        return getIcon("vodkaIcon", "alcohol-icon");
      case 78:
        return getIcon("brandyIcon", "alcohol-icon");
      case 79:
        return getIcon("highballIcon", "alcohol-icon");
      case 80:
        return getIcon("whiskeyIcon", "whiskey-icon");
      case 81:
        return getIcon("liqueurIcon", "alcohol-icon");
      case 82:
        return getIcon("absintheIcon", "alcohol-icon");
      case 83:
        return getIcon("rumIcon", "alcohol-icon");
      case 84:
        return getIcon("sangriaIcon", "alcohol-icon");
      case 85:
        return getIcon("ginIcon", "alcohol-icon");
      case 86:
        return getIcon("sakeIcon", "alcohol-icon");
      case 87:
        return getIcon("sojuIcon", "alcohol-icon");
      case 88:
        return getIcon("martiniIcon", "alcohol-icon");
      case 89:
        return getIcon("shotsIcon", "alcohol-icon");
    }
  };

  if (alcoholPreference?.length > 0) {
    return (
      <div id="profile-alcohol-preference-subcomponent">
        <div className="padding-container">
          <div className="alcohol-preference-label">
            {t("registration.common.drinking_preference")}
          </div>

          <div className="alcohol-preference-container">
            {alcoholPreference?.map((alcohol, index) => (
              <div
                className={`alcohol-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("alcoholPreference")}
                key={index}
              >
                <div className="alcohol-icon-container">
                  {getAlcoholIcon(alcohol?.id)}
                </div>
                <div className="alcohol-item">{alcohol?.locale}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
};

export default ProfileAlcoholPreference;
