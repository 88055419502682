// General
import "./search-card-container.scss";
// Material UI
import { useMediaQuery } from "@mui/material";
// Components
import CategoryTab from "../category-tab/category-tab";
import FilteredView from "./filtered-view/filtered-view";
import RecentlyActiveView from "./recently-active-view/recently-active-view";
import LgbtqRecentlyActiveView from "./lgbtq-recently-active-view/lgbtq-recently-active-view";
import NearbyView from "./nearby-view/nearby-view";
import NewestView from "./newest-view/newest-view";
import CollegeView from "./college-view/college-view";
import PremiumView from "./premium-view/premium-view";
import MasqueradePartyView from "./masquerade-party-view/masquerade-party-view";

const SearchListContainer = () => {
  // MUI variables
  const isMobile = useMediaQuery("(max-width:720px)");

  return (
    <div id="search-search-list-container-subcomponent">
      <div className="search-list-body-container">
        {!isMobile && (
          <div className="category-tab-view">
            <CategoryTab />
          </div>
        )}

        <div className="search-view-container">
          <FilteredView />
          <RecentlyActiveView />
          <LgbtqRecentlyActiveView />
          <NearbyView />
          <NewestView />
          <CollegeView />
          <PremiumView />
          <MasqueradePartyView />
        </div>
      </div>
    </div>
  );
};

export default SearchListContainer;
